<template>
  <div>
    <img class="hidden lg:block fixed top-24 right-0 z-0 h-64" src="@/assets/png/signup img2 right.png" />
    <div class="relative z-1">
      <!--<h1 class="my-4 text-3xl xs:text-5xl text-mint font-extrabold">
        <span>Upload artwork to Sleevenote.</span>
      </h1>-->
      <div class="text-2xl xs:text-3xl mb-8">Send password reset.</div>

      <form v-if="!emailSent" @submit.prevent="submit" class="text-base xs:text-xl max-w-screen-sm">

        <div class="mb-4 flex flex-wrap">
          <div class="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
            <label class="mr-2 w-40">Email</label>
            <input
              v-model="email"
              type="text"
              required
              class="w-full flex-grow bg-gray-200 p-2 rounded-none outline-none text-black">
          </div>
          <div
            v-if="errors.email"
            class="text-sm xs:text-lg text-red-500 w-full">
            {{ errors.email.join(',') }}
          </div>
        </div>

        <div>
          <div class="flex justify-end">
            <button
              type="submit"
              :disabled="loading"
              class="text-white bg-mint shadow-md rounded-xl hover:shadow-sm transition-colors p-2 focus:outline-none w-64 h-12 mb-4"
              :class="{'cursor-not-allowed': loading, '': !loading}">
              <span v-if="!loading">GO</span>
              <span v-else>
                <Loader></Loader>
              </span>
            </button>
          </div>
        </div>

      </form>
      <div v-else class="text-3xl xs:text-5xl text-mint">Email with instructions sent.</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data(){
    return{
      email: null,
      errors: {},
      loading: false,
      emailSent: false
    }
  },
  methods: {
    submit(){
      this.loading = true;
      axios.post(`${process.env.VUE_APP_API_URL}/creator/forgot-password`, {
        email: this.email,
      })
      .then(() => {
        this.emailSent = true
        this.errors = null
      })
      .catch((error) => {
        this.errors = error.response.data.errors
        this.emailSent = false
      })
      .finally(() => {
        this.loading = false
      })
    }
  },
  mounted(){
  },
  created() {
  }
};
</script>
