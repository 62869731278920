<template>
  <div>
    <div class="my-4">
      <h1 class="text-2xl xs:text-3xl mb-8">Edit Your Profile</h1>

      <div class="text-base xs:text-xl">

        <form @submit.prevent="updateName" class="mb-8 space-y-1">
          <div class="flex max-w-sm">
            <div class="font-bold">Name</div>
            <input
              v-model="name"
              type="text"
              autocomplete="none"
              :placeholder="user.name"
              class="ml-2 mb-1 w-full bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
          </div>
          <LoadingButton :title="user.name ? 'Update' : 'Add'" :disabled="!name"  :loading="updatingName" />
          <div class="h-3">
            <div v-if="errors.name && name" class="text-sm text-red-500">{{ errors.name.join(',') }}</div>
            <div v-else-if="!updatingName && nameUpdated" class="text-sm text-green-500">Name updated</div>
          </div>
        </form>

        <form @submit.prevent="updateEmail" class="mb-8 space-y-1">
          <div class="flex max-w-sm">
            <div class="font-bold">Email</div>
            <input
              type="text"
              autocomplete="none"
              v-model="email"
              :placeholder="user.email"
              class="ml-2 mb-1 w-full bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
          </div>
          <div v-if="!user.email" class="text-sm">Add an email to keep your data without a wallet.</div>
          <LoadingButton :title="user.email ? 'Update' : 'Add'" :disabled="!email" :loading="updatingEmail" />
          <div class="h-3">
            <div v-if="errors.email && email" class="text-sm text-red-500">{{ errors.email.join(',') }}</div>
            <div v-else-if="!updatingEmail && emailUpdated" class="text-sm text-green-500">Email updated</div>
          </div>
        </form>

        <form @submit.prevent="updateCompany" class="mb-8 space-y-1">
          <div class="flex max-w-sm">
            <div class="font-bold">Company</div>
            <input
              type="text"
              autocomplete="none"
              v-model="company"
              :placeholder="user.company"
              class="ml-2 mb-1 w-full bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
          </div>
          <LoadingButton :title="user.company ? 'Update' : 'Add'" :disabled="!company" :loading="updatingCompany" />
          <div class="h-3">
            <div v-if="errors.company && company" class="text-sm text-red-500">{{ errors.company.join(',') }}</div>
            <div v-else-if="!updatingCompany && companyUpdated" class="text-sm text-green-500">Company updated</div>
          </div>
        </form>

        <form @submit.prevent="updatePassword" class="mb-8 space-y-1">
          <div class="flex max-w-sm">
            <div class="font-bold">Password</div>
            <input
              v-model="password"
              type="password"
              required
              autocomplete="new-password"
              class="ml-2 mb-1 w-full bg-transparent border-b-2 border-black dark:border-white rounded-none outline-none">
          </div>
          <LoadingButton title="Update" :disabled="!password" :loading="updatingPassword" />
          <div class="h-3">
            <div v-if="errors.password && password" class="text-sm text-red-500">{{ errors.password.join(',') }}</div>
            <div v-else-if="!updatingPassword && passwordUpdated" class="text-sm text-green-500">Password updated</div>
          </div>
        </form>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import LoadingButton from '@/components/LoadingButton'

export default {
  components: {
    LoadingButton
  },
  data(){
    return{
      name: null,
      company: null,
      email: null,
      password: null,
      errors: [],
      updatingEmail: false,
      updatingPassword: false,
      updatingCompany: false,
      updatingName: false,
      updatingLogo: false,
      emailUpdated: false,
      passwordUpdated: false,
      companyUpdated: false,
      nameUpdated: false,
      logoUpdated: false,
      loading: false,
    }
  },
  computed: {
    ...mapState('account', ['user']),
    allowCompanyUpdate(){
      return !this.user.company
    },
  },
  watch: {
    user(value){
      this.email = value.email ? value.email : null
      this.name = value.name ? value.name : null
      this.company = this.user.company ? value.company : null
    },
  },
  methods: {
    updateEmail(){
      this.updatingEmail = true
      this.emailUpdated = false
      this.errors = []

      axios.post(`${process.env.VUE_APP_API_URL}/creator/update`, {
        email: this.email
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.account.token
        }
      })
      .then((response) => {
        this.emailUpdated = true
        const user = response.data.user
        this.$store.commit('account/SET_USER', user)
      })
      .catch((error) => {
        console.log(error)
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.updatingEmail = false
      })
    },
    updatePassword(){
      this.updatingPassword = true
      this.passwordUpdated = false
      this.errors = []

      axios.post(`${process.env.VUE_APP_API_URL}/creator/update`, {
        password: this.password
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.account.token
        }
      })
      .then((response) => {
        this.passwordUpdated = true
        const user = response.data.user
        this.$store.commit('account/SET_USER', user)
      })
      .catch((error) => {
        console.log(error)
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.updatingPassword = false
      })
    },
    updateCompany(){
      this.updateCompany = true
      this.companyUpdated = false
      this.errors = []

      axios.post(`${process.env.VUE_APP_API_URL}/creator/update`, {
        company: this.company
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.account.token
        }
      })
      .then((response) => {
        this.companyUpdated = true
        const user = response.data.user
        this.$store.commit('account/SET_USER', user)
        //this.$router.push({ name: 'usercollection', params: { company: user.company } })
      })
      .catch((error) => {
        console.log(error)
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.updatingCompany = false
      })
    },
    updateName(){
      this.updatingName = true
      this.nameUpdated = false
      this.errors = []

      axios.post(`${process.env.VUE_APP_API_URL}/creator/update`, {
        name: this.name
      }, {
        headers: {
          Authorization: "Bearer " + this.$store.state.account.token
        }
      })
      .then((response) => {
        this.nameUpdated = true
        const user = response.data.user
        this.$store.commit('account/SET_USER', user)
      })
      .catch((error) => {
        console.log(error)
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.updatingName = false
      })
    },
  },
  mounted(){
    if(this.user){
      this.email = this.user.email ? this.user.email : null
      this.name = this.user.name ? this.user.name : null
      this.company = this.user.company ? this.user.company : null
    }

  },
  created() {
  }
};
</script>