<template>
  <div class="py-2">
    <div class="flex w-full space-x-2 text-xxs">
      <!-- back cover -->
      <div class="w-12 h-12 bg-gray-500 float-left cursor-pointer">
        <div
          class="w-12 h-12 bg-gray-500 float-left cursor-pointer hover:bg-gray-400"
          :class="selectedIndex == 0 && 'active'"
          @click.prevent="backCoverSelected"
        >
          <img :src="album.back_cover" class="hover:opacity-50" />
        </div>
        <p>Tracklist</p>
      </div>

      <!-- front cover -->
      <div class="w-12 h-12 bg-gray-500 float-left cursor-pointer">
        <div
          class="w-12 h-12 bg-gray-500 float-left cursor-pointer"
          :class="selectedIndex == 1 && 'active'"
          @click.prevent="frontCoverSelected"
        >
          <img :src="frontCover" class="hover:opacity-50" />
        </div>
        <p>Cover</p>
      </div>

      <!-- artwork -->
      <draggable v-if="album.artwork.length > 0" v-model="album.artwork" group="artwork"
        class="flex shrink-0 space-x-2">
        <div
          class="w-12 h-12 bg-gray-500 float-left cursor-pointer"
          v-for="(artwork, index) in album.artwork"
          :key="index + 'artwork'"
        >
          <div
            class="w-12 h-12 bg-gray-500 float-left cursor-pointer"
            :class="selectedIndex == index + 2 && 'active'"
            @click.prevent="artworkSelected(index)"
          >
            <img :src="artwork" class="hover:opacity-50" />
          </div>
          <p>Booklet {{ index + 1 }}</p>
        </div>
      </draggable>

      <div
        class="w-12 h-12 bg-gray-500 float-left"
        v-for="index in maxNumArtwork - album.artwork.length"
        :key="index + 'placeholder'"
      >
        <div class="w-12 h-12 bg-gray-500 float-left hover:bg-gray-400">
          <ImageUpload
            v-if="index == 1 && showUploadBtn"
            ref="artworkUpload"
            :multiple="true"
            @change="onArtworkChanged"
          >
            <button class="upload-btn" type="button">
              <img src="@/assets/svg/add.svg" />
            </button>
          </ImageUpload>
          <div class="w-12 h-12 bg-gray-500 float-left" v-else></div>
        </div>
        <p>Booklet {{ index + album.artwork.length }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import draggable from "vuedraggable";
import ImageUpload from "@/components/ImageUpload.vue";

export default {
  name: "Navigator",
  components: { draggable, ImageUpload },
  props: {
    idUpload: String,
    artworkUpload: String,
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    ...mapState("album", {
      album: (state) => state.album,
      maxNumArtwork: (state) => state.maxNumArtwork,
    }),
    showUploadBtn() {
      return this.album.back_cover !== null;
    },
    frontCover(){
      return this.album.front_cover_from_service ? this.album.service_front_cover : this.album.front_cover
    }
  },
  methods: {
    ...mapGetters("album", {
      getFrontCover: "getFrontCover",
    }),
    onBackCoverChanged() {
      console.log("onBackCoverChanged");
      this.$root.$emit("backCoverUploaded", this.$refs.backCoverUpload.images);
    },
    onArtworkChanged() {
      this.$root.$emit("artworkUploaded", this.$refs.artworkUpload[0].images);
    },
    backCoverSelected() {
      this.selectedIndex = 0;
      this.$root.$emit("changeSelection", 0);
    },
    frontCoverSelected() {
      this.selectedIndex = 1;
      this.$root.$emit("changeSelection", 1);
    },
    artworkSelected(index) {
      this.selectedIndex = index + 2;
      this.$root.$emit("changeSelection", index + 2);
    },
    //showUploadButton() {
    //this.showUploadBtn = true;
    //},
    changeSelection(e) {
      this.selectedIndex = e;
    },
  },
  mounted() {
    //this.$root.$on("backCoverUploaded", this.showUploadButton);
    this.$root.$on("changeSelection", this.changeSelection);
  },
  beforeDestroy() {
    //this.$root.$off("backCoverUploaded", this.showUploadButton);
    this.$root.$off("changeSelection", this.changeSelection);
  },
};
</script>

<style scoped>
/*.navigator {
  padding: 0 1.5em;
}
.image-list-container {
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  height: 64px;
}
.image-list-item {
  min-height: 50px;
  min-width: 50px;
  width: 50px;
  height: 50px;
  float: left;
  border: none;
  background-color: rgb(90, 90, 90);
  cursor: pointer;
}
.image-list-item img {
  width: 100%;
}
.active {
  outline: 2px solid rgb(0, 255, 0);
  outline-offset: -2px;
}
.image-list-container .image-list-item {
  margin-right: 0.5rem;
}
.image-list-container .image-list-item:first-child {
  margin-left: 0;
}
.image-list-item > p {
  font-size: 0.6em;
  padding-top: 43px;
  position: absolute;
  pointer-events: none;
}
.ghost img {
  opacity: 0.5;
}*/
</style>
