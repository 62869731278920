<template>
  <div class="submissions">
    <h3 class="text-black dark:text-white text-xl my-4">Your pending submissions</h3>
    <SubmissionsList  :isLoading="isLoading"
                      :submissions="pending"
                      :showReplaceLink="true"
                      :allowDelete="true"
                      @delete-submission="deleteSubmissionClicked"></SubmissionsList>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SubmissionsList from "@/components/SubmissionsList.vue";

export default {
  name: "PendingSubmissions",
  components: {
    SubmissionsList,
  },
  data() {
    return {
      isLoading: false,
      pending: [],
    };
  },
  mounted() {
    this.getSubmissions()
  },
  methods: {
    ...mapActions("submissions", {
      getPendingSubmissions: "getPendingSubmissions",
      deleteSubmission: "deleteSubmission"
    }),
    getSubmissions(){
      this.isLoading = true;
      this.getPendingSubmissions(1)
        .then((response) => {
          this.pending = response.data.pending;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.destroyToken().finally(() => {
              this.$router.replace({
                name: "login",
                query: { redirect: this.$route.fullPath },
              });
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteSubmissionClicked(submission){
      if(!window.confirm("Are you sure?")){
        return
      }

      this.deleteSubmission(submission)
          .then(() => {
            this.getSubmissions()
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.destroyToken().finally(() => {
                this.$router.replace({
                  name: "login",
                  query: { redirect: this.$route.fullPath },
                });
              });
            }
          })
          .finally(() => {
          });
    }
  },
};
</script>
