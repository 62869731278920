<template>
  <div class="w-full px-4 py-2 text-sm h-10 z-50 bg-light dark:bg-dark text-black dark:text-white flex justify-between items-center">
    <div>
      <Logo></Logo>
    </div>

    <div>
      <AdminNav v-if="showAdminNav"></AdminNav>
      <UserNav v-else-if="showUserNav"></UserNav>
    </div>

    <div class="flex">
      <router-link :to="{ name: 'login' }" v-if="showLoginLink" class="hover:underline">Log in</router-link>
      <router-link :to="{ name: 'profile' }" v-if="loggedIn()" class="hover:underline">
        {{ user.name }}
      </router-link>
      <span v-if="loggedIn() && user.is_admin" class="small">&nbsp;(ADMIN)</span>

      <button v-if="loggedIn()" @click.prevent="logout" class="ml-2 hover:underline w-16 flex justify-center text-center">
        <div v-if="!loggingOut">Log out</div>
        <div v-else class="w-4">
          <Loader />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Logo from "@/components/header/Logo.vue";
import AdminNav from "@/components/header/nav/AdminNav.vue";
import UserNav from "@/components/header/nav/UserNav.vue";

export default {
  name: "Header",
  components: {
    Logo,
    AdminNav,
    UserNav,
  },
  data() {
    return {
      loggingOut: false,
    };
  },
  computed: {
    ...mapState('account', ['user']),
    showLoginLink() {
      return !this.loggedIn() &&
        this.$route.name !== 'login';
    },
    showAdminNav() {
      return this.loggedIn() && this.user.is_admin;
    },
    showUserNav(){
      return this.loggedIn() && this.$route.name !== 'login' &&
        this.$route.name !== 'request' &&
        this.$route.name !== 'signup' &&
        this.$route.name !== 'forgotten-password' &&
        this.$route.name !== 'password-reset';
    }
  },
  methods: {
    ...mapActions("account", {
      destroyToken: "destroyToken",
    }),
    ...mapGetters("account", {
      loggedIn: "loggedIn"
    }),
    logout() {
      this.loggingOut = true;
      this.destroyToken()
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .finally(() => {
          this.loggingOut = false;
        });
    },
  },
};
</script>