<template>
  <div class="submission-list">
    <table
      v-if="submissions && submissions.length > 0"
      class="table-fixed"
      cellspacing="0"
      width="100%"
    >
      <thead>
        <tr>
          <th class="text-left">Artist</th>
          <th class="text-left">Release</th>
          <th class="text-left">Artwork preview</th>
          <th>Link</th>
          <th>Stats (beta)</th>
        </tr>
      </thead>
      <tr v-for="(submission, index) in submissions" :key="index">
        <td class="truncate">{{ submission.artist }}</td>
        <td class="truncate">{{ submission.title }}</td>
        <td>
          <div class="flex mb-4">
            <img :src="submission.back_cover" width="75px" height="75px" class="w-18 h-18" />
            <img :src="submission.front_cover" width="75px" height="75px" class="w-18 h-18" />
            <div v-for="(cover, index) in submission.artwork" :key="index">
              <img :src="cover" width="75px" height="75px" class="w-18 h-18" />
            </div>
          </div>
        </td>
        <td class="space-x-4 text-center">
          <div v-if="showPreviewLink">
            <a :href="releaseLink(submission.id)" target="_blank" class="underline hover:no-underline">View</a> | <button @click="copyReleaseLink(submission.id)" class="underline hover:no-underline">Copy</button>
          </div>
          <router-link v-if="showReplaceLink" :to="`/submission/${submission.id}`" class="underline hover:no-underline">Edit</router-link>
          <button v-if="allowDelete" @click.stop.prevent="$emit('delete-submission', submission.id)" class="underline hover:no-underline">Delete</button>
        </td>
        <td class="text-center">
          <div v-if="showStats">
            <div>Total: {{ submission.total_visits }}</div>
            <div>Past 30d: {{ submission.visits }}</div>
          </div>
        </td>
      </tr>
    </table>

    <div v-else>
      <div v-if="!isLoading">
        <div class="opacity-50">None</div>
      </div>
      <div v-else class="w-24">
        <Loader />
      </div>
    </div>

    <transition name="fade">
      <LinkCopiedModal v-if="showLinkCopiedModal" />
    </transition>
  </div>
</template>

<script>
import LinkCopiedModal from '@/components/modals/LinkCopiedModal'
export default {
  components: {
    LinkCopiedModal
  },
  props: {
    submissions: Array,
    isLoading: Boolean,
    showPreviewLink: {
      type: Boolean,
      default: false,
    },
    showReplaceLink: {
      type: Boolean,
      default: false,
    },
    allowDelete: {
      type: Boolean,
      default: false,
    },
    showStats: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      showLinkCopiedModal: false
    }
  },
  methods: {
    releaseLink(id){
      return `${process.env.VUE_APP_PLAYER_URL}/release/${id}`
    },
    copyReleaseLink(id){
      navigator.clipboard.writeText(this.releaseLink(id))
      this.showLinkCopiedModal = true
      setTimeout(() => {
        this.showLinkCopiedModal = false
      }, 1500)
    },
    getAlbumFrontCover(album, size) {
      if (album.id) {
        if (album.front_cover_from_service) {
          return album.front_cover.replace("{w}x{h}", size + "x" + size);
        } else {
          return album.front_cover + "?w=" + size;
        }
      }
      return album.front_cover.replace("{w}x{h}", size + "x" + size);
    },
    getAlbumBackCover(album, size) {
      if (album.id) {
        return album.back_cover + "?w=" + size;
      }
      return null;
    },
    getArtworkImage(cover, size) {
      return cover + "?w=" + size;
    }
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>