<template>
  <div class="toolbar flex justify-start w-full z-50">
    <div class="image-list-container w-full">
      <div v-if="!bIsHidden" class="image-list-item tooltip">
        <button
          class="w-8 toolbar-btn tooltip"
          type="button"
          @click.prevent="rectMode"
          :disabled="bIsDisabled"
          v-bind:class="{ active: mode == modes.DRAW.RECT }"
        >
          <img src="@/assets/svg/toolbar/rectangle.svg" width="100%" />
          <span class="tooltiptext">Rectangle shape</span>
        </button>
      </div>
      <div v-if="!bIsHidden" class="image-list-item tooltip">
        <button
          class="w-8 toolbar-btn tooltip"
          type="button"
          @click.prevent="polyMode"
          :disabled="bIsDisabled"
          v-bind:class="{ active: mode == modes.DRAW.POLY }"
        >
          <img src="@/assets/svg/toolbar/polygon.svg" width="100%" />
          <span class="tooltiptext">Polygon shape</span>
        </button>
      </div>
      <div v-if="!bIsHidden" class="image-list-item tooltip">
        <button
          class="w-8 toolbar-btn"
          type="button"
          @click.prevent="editMode"
          :disabled="bIsDisabled"
          v-bind:class="{ active: mode == modes.EDIT }"
        >
          <img src="@/assets/svg/toolbar/edit.svg" width="100%" />
          <span class="tooltiptext">Edit shape</span>
        </button>
      </div>
      <div v-if="!bIsHidden" class="image-list-item tooltip">
        <button
          class="w-8 toolbar-btn"
          type="button"
          @click.prevent="eraseMode"
          :disabled="bIsDisabled"
          v-bind:class="{ active: mode == modes.ERASE }"
        >
          <img src="@/assets/svg/toolbar/erase.svg" width="100%" />
          <span class="tooltiptext">Erase shape</span>
        </button>
      </div>
      <div v-if="!bIsHidden" class="image-list-item tooltip">
        <button class="w-8 toolbar-btn" type="button" @click.prevent="clear" :disabled="bIsDisabled">
          <img src="@/assets/svg/toolbar/clear.svg" width="100%" />
          <span class="tooltiptext">Clear all shapes</span>
        </button>
      </div>
      <div v-if="!bDeleteIsHidden" class="image-list-item float-right tooltip">
        <button
          class="w-8 toolbar-btn delete fill-black dark:fill-white"
          type="button"
          @click.prevent="deleteArtwork"
          :disabled="bDeleteIsDisabled"
        >
          <svg class="w-8 h-8" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <g>
              <path d="M383.398,146.487h-16.033h-52.064V99.155H196.699v47.332h-52.064h-16.033v20h16.033v246.357h222.73V166.487h16.033
                V146.487z M216.699,119.155h78.602v27.332h-78.602V119.155z M347.365,392.845h-182.73V166.487h32.064h118.602h32.064V392.845z"/>
              <rect x="295.301" y="197.902" width="20" height="157.379"/>
              <rect x="196.699" y="197.902" width="20" height="157.379"/>
              <rect x="246" y="197.902" width="20" height="157.379"/>
            </g>
          </svg>
          <span class="tooltiptext">Delete image</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toolbar",
  data() {
    return {
      bIsDisabled: false,
      bIsHidden: false,
      bDeleteIsDisabled: true,
      bDeleteIsHidden: false,
      modes: {
        EDIT: "EDIT",
        DRAW: {
          RECT: "RECT",
          POLY: "POLY",
        },
        SELECT: "SELECT",
        ERASE: "ERASE",
      },
      mode: null,
    };
  },
  methods: {
    enable() {
      this.bIsDisabled = false;
      this.mode = this.modes.DRAW.RECT;
    },
    disable() {
      this.bIsDisabled = true;
    },
    hide() {
      this.bIsHidden = true;
    },
    show() {
      this.bIsHidden = false;
    },
    enableDelete() {
      this.bDeleteIsDisabled = false;
    },
    disableDelete() {
      this.bDeleteIsDisabled = true;
    },
    hideDelete() {
      this.bDeleteIsHidden = true;
    },
    showDelete() {
      this.bDeleteIsHidden = false;
    },
    changeMode(m) {
      console.log("changeMode: " + m);
      this.mode = m;
    },
    editMode() {
      this.mode = this.modes.EDIT;
      this.$emit("modeChanged", this.mode);
    },
    rectMode() {
      this.mode = this.modes.DRAW.RECT;
      this.$emit("modeChanged", this.mode);
    },
    polyMode() {
      this.mode = this.modes.DRAW.POLY;
      this.$emit("modeChanged", this.mode);
    },
    eraseMode() {
      this.mode = this.modes.ERASE;
      this.$emit("modeChanged", this.mode);
    },
    duplicate() {
      this.$emit("buttonPressed", "DUPLICATE");
    },
    undo() {
      this.$emit("buttonPressed", "UNDO");
    },
    redo() {
      this.$emit("buttonPressed", "REDO");
    },
    clear() {
      this.$root.$emit("buttonPressed", "CLEAR");
    },
    deleteArtwork() {
      this.$emit("buttonPressed", "DELETE");
    },
  },
  mounted() {
    this.mode = this.modes.DRAW.RECT;
  },
};
</script>

<style scoped>
.toolbar {
  padding: 0 0.3rem;
  margin: 1rem;
  width: 68vh;
}
.toolbar-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.toolbar-btn {
  border: none;
  color: black;
  background-color: white;
  cursor: pointer;
  border: 1px solid #999999;
  background-color: #cccccc;
  position: relative;
  z-index: 100;
}
.toolbar-btn .right {
  float: right;
}
button.toolbar-btn:disabled,
button.toolbar-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.toolbar-btn:hover {
  background: #bbbbbb;
}
.active, .active:hover {
  background: yellowgreen;
}
.image-list-container {

}
.image-list-container .image-list-item {
  width: 30px;
  height: 30px;
}
.image-list-item:last-of-type {
  margin-left: auto;
}
.image-list-container .image-list-item:not(:last-child) {
  margin-right: 0.5rem;
}
.image-list-container .image-list-item:first-child {
  margin-left: 0;
}
.image-list-item > img {
  width: 100%;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  border: 2px solid black;
  color: black;
  text-align: center;
  padding: 5px;

  position: absolute;
  width: 110px;
  top: 40px;
  left: 50%;
  z-index: 10;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  z-index: 100;
}
</style>
