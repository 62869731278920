<template>
  <div>
    <div>
      <h2>To Approve</h2>
      <div v-if="isLoading" class="w-24">
        <Loader />
      </div>
      <div v-else-if="submissions.length == 0">
        <h2>No submissions!</h2>
      </div>
      <div v-else>
        <table class="table-fixed" cellspacing="0" width="100%">
          <thead>
            <tr>
              <th class="text-left">Artist</th>
              <th class="text-left">Album</th>
              <th></th>
              <th class="text-left">User email</th>
              <th></th>
            </tr>
          </thead>
          <tr v-for="(submission, index) in submissions" :key="index">
            <td class="truncate">{{ submission.artist }}</td>
            <td class="truncate">{{ submission.title }}</td>
            <td>
              <div class="flex mb-4">
                <img :src="submission.back_cover" width="75px" height="75px" class="w-18 h-18" />
                <img :src="submissionFrontCover(submission)" width="75px" height="75px" class="w-18 h-18" />
              </div>
            </td>
            <td>
              <a v-if="submission.user" :href="`mailto:` + submission.user.email" class="underline hover:no-underline">{{submission.user.email}}</a>
            </td>
            <td class="space-x-4">
              <router-link :to="`/submission/${submission.id}`" class="underline hover:no-underline">View</router-link>
              <button @click.prevent="deleteAlbumClicked(submission)" class="underline hover:no-underline">
                Delete
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ToApprove",
  data() {
    return {};
  },
  computed: {
    ...mapState("submissions", {
      submissions: (state) => state.submissions,
      isLoading: (state) => state.isLoading,
    }),
  },
  methods: {
    ...mapMutations("submissions", {
      setSubmissions: "setSubmissions",
    }),
    ...mapActions("submissions", {
      getSubmissions: "getSubmissions",
      deleteSubmission: "deleteSubmission",
    }),
    submissionFrontCover(submission){
      return submission.front_cover_from_service ? submission.service_front_cover : submission.front_cover
    },
    deleteAlbumClicked(album) {
      if (window.confirm("Are you sure you want to delete this?")) {
        if (window.confirm("Are you sure you are sure?")) {
          this.deleteSubmissionFromDb(album.id);
        }
      }
      /*if (window.confirm("Are you sure you want to delete this?")) {
        if (window.confirm("Are you sure you are sure?")) {
          this.deleteAlbumFromDb(album.id);
        }
      }*/
    },
    deleteSubmissionFromDb(id) {
      //this.isLoading = true;
      this.deleteSubmission(id)
        .then(() => {
          //this.alertBeforeLeaving = false;
          //this.$router.push({ name: "submissions" });
        })
        .catch((error) => {
          alert("Oh no! There was an error: " + error);
          if (error.response.status === 401) {
            this.destroyToken().finally(() => {
              this.$router.replace({
                name: "login",
                query: { redirect: this.$route.fullPath },
              });
            });
          }
        })
        .finally(() => {
          //this.isLoading = false;
          this.getSubmissions().then((response) => {
            this.setSubmissions(response.data.submissions);
          });
        });
    },
  },
  mounted() {
    //let self = this;
    this.setSubmissions([]);
    this.getSubmissions().then((response) => {
      this.setSubmissions(response.data.submissions);
    });
  },
};
</script>