<template>
  <div>
    <img class="hidden lg:block fixed top-24 right-0 z-0 h-64" src="@/assets/png/signup img2 right.png" />
    <div class="relative z-1">
      <h1 class="my-4 text-3xl xs:text-5xl text-mint font-extrabold">
        <span>Create releases on Sleevenote</span>
      </h1>
      <div class="text-2xl xs:text-3xl mb-8">Upload full artwork to share with listeners</div>

      <div @submit.prevent="submit" class="text-base xs:text-xl max-w-screen-sm space-y-4">

        <div>
          <a href="https://music.sleevenote.com" target="_blank" class="underline decoration-4 hover:no-underline">Check it out in action</a>
        </div>

        <div class="sm:space-x-4 space-y-4 sm:space-y-0">
          <router-link
            :to="{ name: 'request' }"
            tag="button"
            class="text-white dark:text-black bg-mint shadow-md rounded-xl hover:shadow-sm transition-colors p-2 focus:outline-none w-64 h-12">
            <span>Request access</span>
          </router-link>

          <router-link
            :to="{ name: 'login' }"
            tag="button"
            class="text-white dark:text-black bg-teal shadow-md rounded-xl hover:shadow-sm transition-colors p-2 focus:outline-none w-64 h-12">
            <span>Log in</span>
          </router-link>
        </div>

        <div>
          <a href="https://sleevenote.notion.site/Sleevenote-FAQs-96a16b0dbe68430ea8c7e071d5eace15" target="_blank" class="underline decoration-4 hover:no-underline">Read the FAQs</a>
        </div>
      </div>
    </div>
    <img class="-mx-4 h-auto max-h-64 mt-12" src="@/assets/png/signup img1 left.png" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      email: '',
      password: '',
      errors: {},
      loading: false,
    };
  },
  methods: {
    ...mapState('account', ['user']),
    ...mapActions("account", ["retrieveToken", "sendPasswordResetEmail"]),
    submit() {
      this.loading = true;
      this.retrieveToken({
        email: this.email,
        password: this.password,
      })
      .then(() => {
        this.loading = false;
        if (this.$store.state.account.user.is_admin) {
          this.$router.push("/toapprove");
        } else {
          this.$router.push(this.$route.query.redirect || "/submissions");
        }
      })
      .catch((error) => {
        this.loading = false;
        this.errors = error.response.data.message
        this.password = "";
      });
    },
  },
};
</script>