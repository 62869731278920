<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <div class="field">
            <slot></slot>
          </div>
          <div class="field">&nbsp;</div>
          <div class="field" style="margin-bottom:0">
            <div class="btn-grp">
              <button @click.prevent="$emit('ok')">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralModal",
  data() {
    return {};
  },
};
</script>

<style scoped>
.field {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  margin-bottom: 1em;
}

.btn-grp {
  display: flex;
  justify-content: flex-end;
  vertical-align: middle;
}

input[type="checkbox"] {
  background: none;
  border: 0;
  cursor: pointer;
  width: 3em;
  height: 3em;
  outline: none;
  padding: 0;
}

button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0.6em;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font: 700 1em Inter, sans-serif;
  line-height: normal;
  height: 2.4em;
  line-height: 1em;
  outline: none;
  padding: 0 2em;
}

button:hover {
  color: black;
  background: #fff;
  border: 2px solid #fff;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.5em;
}
</style>
