<template>
  <div>
    <img class="hidden lg:block fixed top-24 right-0 z-0 h-64" src="@/assets/png/signup img2 right.png" />
    <div class="relative z-1">
      <h1 class="my-4 text-3xl xs:text-5xl text-mint font-extrabold">
        <span>Create releases on Sleevenote</span>
      </h1>
      <div class="text-2xl xs:text-3xl mb-8">Request an account below, we will get back to you promptly.</div>

      <form v-if="!success" @submit.prevent="submit" class="text-base xs:text-xl max-w-screen-sm">

        <div class="mb-4 flex flex-wrap">
          <div class="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
            <label class="mr-2 w-40">Name</label>
            <input
              v-model="name"
              type="text"
              required
              autocapitalize="off"
              class="w-full flex-grow bg-gray-200 p-2 rounded-none outline-none text-black">
          </div>
          <div
            v-if="errors.name"
            class="text-sm xs:text-lg text-red-500 w-full">
            {{ errors.name.join(',') }}
          </div>
        </div>

        <div class="mb-4 flex flex-wrap">
          <div class="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
            <label class="mr-2 w-40">Artist/Label</label>
            <input
              v-model="company"
              type="text"
              required
              autocapitalize="off"
              class="w-full flex-grow bg-gray-200 p-2 rounded-none outline-none text-black">
          </div>
          <div
            v-if="errors.company"
            class="text-sm xs:text-lg text-red-500 w-full">
            {{ errors.company.join(',') }}
          </div>
        </div>

        <div class="mb-4 flex flex-wrap">
          <div class="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
            <div class="mr-2 w-full md:w-40">
              <label>Extra info</label>
              <div class="text-xs">Tell us a bit about you and the releases you are planning to create. (Optional)</div>
            </div>
            <textarea
              v-model="info"
              type="text"
              rows="3"
              class="w-full flex-grow bg-gray-200 p-2 rounded-none outline-none text-black"></textarea>
          </div>
          <div
            v-if="errors.info"
            class="text-sm xs:text-lg text-red-500 w-full">
            {{ errors.info.join(',') }}
          </div>
        </div>

        <div class="mb-4 flex flex-wrap">
          <div class="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
            <label class="mr-2 w-40">Email</label>
            <input
              v-model="email"
              type="text"
              required
              class="w-full flex-grow bg-gray-200 p-2 rounded-none outline-none text-black">
          </div>
          <div
            v-if="errors.email"
            class="text-sm xs:text-lg text-red-500 w-full">
            {{ errors.email.join(',') }}
          </div>
        </div>

        <div>
          <div class="flex justify-end">
            <button
              type="submit"
              :disabled="loading"
              class="text-white dark:text-black bg-mint shadow-md rounded-xl hover:shadow-sm transition-colors p-2 focus:outline-none w-64 h-12 mb-4"
              :class="{'cursor-not-allowed': loading, '': !loading}">
              <span v-if="!loading">GO</span>
              <span v-else>
                <Loader></Loader>
              </span>
            </button>
          </div>

          <div class="text-base xs:text-lg mb-2">Have an account? <router-link
            :to="{ name: 'login' }"
            class="shrink-0 underline hover:no-underline">Log in</router-link>
          </div>
          <div class="text-base xs:text-lg mb-2">Want to know more? <a href="https://sleevenote.notion.site/Sleevenote-FAQs-96a16b0dbe68430ea8c7e071d5eace15" target="_blank" class="shrink-0 underline hover:no-underline">Read the FAQs</a>
          </div>

        </div>

      </form>
      <div v-else class="my-8 text-3xl xs:text-5xl text-mint">Thanks! Your request has been received. We will be in touch soon.</div>

    </div>
    <img class="-mx-4 h-auto max-h-64" src="@/assets/png/signup img1 left.png" />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import axios from 'axios'

export default {
  name: "Signup",
  props: {
    dataSuccessMessage: {
      type: String,
    },
  },
  data() {
    return {
      isMobile,
      name: '',
      email: '',
      company: '',
      info: '',
      errors: {},
      success: false,
      loading: false,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      axios.post(`${process.env.VUE_APP_API_URL}/creator/request`, {
        email: this.email,
        name: this.name,
        company: this.company,
        info: this.info
      })
      .then(() => {
        this.success = true
      })
      .catch((error) => {
        this.errors = error.response.data.message
      })
      .finally(() => {
        this.loading = false
      })
    },
  },
  mounted() {},
};
</script>