import axios from "axios";

const state = {
  user: window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null,
  token: window.localStorage.getItem("access_token") || null,
  returning_visitor: window.localStorage.getItem("returning_visitor") || null,
};

// getters
const getters = {
  loggedIn(state) {
    return (
      state.token !== null &&
      state.token !== "undefined" &&
      state.user !== null &&
      state.user !== "undefined"
    );
  },
  getUser(state) {
    if(state.user){
      return JSON.parse(state.user);
    }else{
      return null;
    }
  },
  userName(state) {
    if(state.user){
      return JSON.parse(state.user).name;
    }else{
      return null;
    }
  },
  userId(state) {
    if(state.user){
      return JSON.parse(state.user).id;
    }else{
      return null;
    }
  },
  isAdmin(state) {
    if(state.user){
      return state.user.is_admin
    }else{
      return false
    }
  },
  isReturningVisitor(state) {
    return state.returning_visitor;
  }
};

// mutations
const mutations = {
  retrieveToken(state, token) {
    state.token = token;
    window.localStorage.setItem("access_token", token)
  },
  destroyToken(state) {
    state.token = null;
  },
  SET_USER(state, user) {
    state.user = user;
    window.localStorage.setItem("user", JSON.stringify(user))
  },
};

// actions
const actions = {
  setIsReturningVisitor() {
    window.localStorage.setItem("returning_visitor", "true");
  },
  destroyToken(context) {
    axios.defaults.withCredentials = false;
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + context.state.token;

    if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
        axios
          .post("/logout")
          .then((response) => {
            window.localStorage.removeItem("access_token");
            window.localStorage.removeItem("user");
            context.commit("destroyToken");
            resolve(response);
          })
          .catch((error) => {
            window.localStorage.removeItem("access_token");
            window.localStorage.removeItem("user");
            context.commit("destroyToken");
            reject(error);
          });
      });
    }
  },
  retrieveToken(context, credentials) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/creator/login`, {
        email: credentials.email,
        password: credentials.password,
      })
      .then((response) => {
        const token = response.data.token
        const user = response.data.user
        context.commit("retrieveToken", token)
        context.commit("SET_USER", user)
        resolve(response)
      })
      .catch((error) => {
        this.errors = error.response.data.message
        reject(error)
      })
      .finally(() => {
        this.loading = false
      })
    });
  },
  registerUser(context, credentials) {
    axios.defaults.withCredentials = false;
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/creator/register`, {
          name: credentials.name,
          email: credentials.email,
          company: credentials.company,
          password: credentials.password,
          password_confirmation: credentials.password_confirmation,
        })
        .then((response) => {
          console.log(response);
          const token = response.data.token
          const user = response.data.user
          context.commit("retrieveToken", token)
          context.commit("SET_USER", user)
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  sendPasswordResetEmail(context, credentials) {
    axios.defaults.withCredentials = false;
    return new Promise((resolve, reject) => {
      axios
        .post("/password/email", {
          email: credentials.email
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPassword(context, credentials) {
    axios.defaults.withCredentials = false;
    return new Promise((resolve, reject) => {
      axios
        .post("/password/reset", {
          email: credentials.email,
          password: credentials.password,
          password_confirmation: credentials.password,
          token: credentials.token
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUser(context) {
    alert('getUser')
    axios.defaults.withCredentials = false;
    return new Promise((resolve, reject) => {
      axios
        .post("/user", this.state.token)
        .then((response) => {
          console.log(response);
          const user = response.data
          localStorage.setItem("user", user);
          context.commit("SET_USER", user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePassword(context, data) {
    console.log(data);
    axios.defaults.withCredentials = false;
    return new Promise((resolve, reject) => {
      axios
        .post("/", this.state.token)
        .then((response) => {
          console.log(response);
          const user = JSON.stringify(response.data);
          window.localStorage.setItem("user", user);
          context.commit("SET_USER", user);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
