<template>
  <div class="space-y-4 max-w-screen-lg">
    <p class="text-2xl">Terms of use: Supplying artwork</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p></p>

    <h2>1.0 Officially submitted artwork</h2>
    <p>&nbsp;</p>
    <p>1.1 You shall remain the owner of the artwork and other protected content provided by you to us (“the Artwork”) but you agree that we shall have a non-exclusive, royalty free, transferrable worldwide licence to use the Artwork for the Sleevenote service (“the Service”), and in promotion of the service.</p>
    <p>&nbsp;</p>
    <p>1.2 You represent and warrant that: (i) you own the Artwork posted by you on or through the Service or otherwise have the right to grant the rights and licenses set forth in these Terms of Use; (ii) the posting and use of your Artwork on or through the Service does not violate, misappropriate or infringe on the rights of any third party, including, without limitation, privacy rights, publicity rights, copyrights, trademark and/or other intellectual property rights; (iii) you agree to pay for all royalties, fees, and any other monies owed, if any, by reason of Artwork you post on or through the Service; and (iv) you have the legal right and capacity to enter into these Terms of Use in your jurisdiction.</p>
    <p>&nbsp;</p>
    <p>1.3 You have the right to revoke artwork at any point, either via by tools provided by the Service or by written request to <a href="mailto:creatorsupport@sleevenote.com" target="_blank">creatorsupport@sleevenote.com</a></p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h2>2.0 Fan submitted artwork</h2>
    <p>&nbsp;</p>
    <p>2.1 All artwork and content remains the copyright of it’s original owner or licensees. By submitting artwork you are not pertaining to ownership in any form, including but not limited to reformatting and graphic retouching, if required this content will be made available to the original content owner.</p>
    <p>&nbsp;</p>
    <p>2.2 You are agreeing to not provide any malicious, offensive or illegal imagery to the service.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h2>3.0 The Sleevenote Service</h2>
    <p>&nbsp;</p>
    <p>3.1 Although it is Sleevenote's intention for the Service to be available as much as possible, there will be occasions when the Service may be interrupted, including, without limitation, for scheduled maintenance or upgrades, for emergency repairs, or due to failure of telecommunications links and/or equipment. Also, Sleevenote reserves the right to remove any Artwork from the Service for any reason, without prior notice. We reserve the right to modify or terminate the Service or your access to the Service for any reason, without notice, at any time, and without liability to you.</p>
    <p>&nbsp;</p>
    <p>3.2 The Sleevenote application programming interfaces and underlying code (“APIs”) may be protected by copyrights, trademarks, service marks, international treaties, and/or other proprietary rights and laws of the U.S. and other countries. You agree to abide by all applicable proprietary rights laws and other laws. Sleevenote owns all rights, title, and interest in and to the Sleevenote APIs. These terms grant you no right, title, or interest in any intellectual property owned or licensed by Sleevenote, including (but not limited to) the Sleevenote APIs and Sleevenote trademarks.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>For any other legal matters contact us at <a href="mailto:creatorsupport@sleevenote.com" target="_blank" class="underline hover:no-underline">creatorsupport@sleevenote.com</a></p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>

export default {
  name: "Terms",
};
</script>

<style scoped>
</style>