import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Loader from '@/components/Loader'
import '@/assets/css/tailwind.css'

Vue.config.productionTip = true;

Vue.component("Loader", Loader);
Vue.component('pagination', require('laravel-vue-pagination'));

new Vue({
  store: store,
  router: router,
  render: (h) => h(App),
}).$mount("#app");
