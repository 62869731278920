<template>
  <div>
    <Header />
    <DesktopOnlyModal v-if="showDesktopOnlyModal"></DesktopOnlyModal>
    <router-view v-else class="m-4"></router-view>
  </div>
</template>

<script>
import axios from 'axios'
import { isMobile } from 'mobile-device-detect'
import { mapGetters } from 'vuex'
import DesktopOnlyModal from '@/components/modals/DesktopOnlyModal'
import Header from '@/components/header/Header'

export default {
  name: "app",
  components: {
    DesktopOnlyModal,
    Header,
  },
  data(){
    return{
      isMobile
    }
  },
  computed: {
    ...mapGetters('account', [ 'loggedIn' ]),
    showDesktopOnlyModal(){
      return this.isMobile && this.$route.meta.requiresDesktop
    }
  },
  methods: {
    async getUser(){
      this.loading = true;

      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/creator`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.account.token
          }
        })

        const user = response.data
        this.$store.commit('account/SET_USER', user)

      } catch(error) {
        console.log(error)
        this.errors = error.response.data.message
      }
    },
  },
  mounted(){
    if(this.loggedIn){
      this.getUser()
    }
  }
};
</script>

<style>
/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: transparent white;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 0.6em;
  border: none;
}
</style>
