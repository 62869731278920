<template>
  <div class="upload-btn-wrapper" @click="selectImage">
    <slot></slot>
    <input
      ref="fileInput"
      type="file"
      :name="name"
      :id="id"
      accept="image/jpg, image/jpeg, image/png, image/gif"
      @change="onFileChange"
      :multiple="multiple"
      style="display:none"
    />
  </div>
</template>

<script>
import CONSTANTS from "@/constants";
export default {
  name: "ImageUpload",
  props: {
    name: {
      type: String,
      default: null,
    },
    id: {
      type: [String, Number],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [],
      loadedCount: 0,
      minImageSize: CONSTANTS.MIN_ARTWORK_SIZE,
      maxImageSize: CONSTANTS.MAX_ARTWORK_SIZE,
      maxFileSize: CONSTANTS.MAX_FILE_SIZE,
    };
  },
  methods: {
    onClick() {
      //if (!this.imageSelected) {
      this.selectImage();
      //  return;
      //}
      this.$emit("click");
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      for(var i =0; i<files.length; i++){
        let file = files[i]
        if (file.type.substr(0, 9) !== "image/jpg" &&
            file.type.substr(0, 9) !== "image/png" &&
            file.type.substr(0, 10) !== "image/jpeg" &&
            file.type.substr(0, 9) !== "image/gif") {
          window.alert("Image must be png, jpg or gif");
          return;
        }

        if (file.size > this.maxFileSize) {
          window.alert("Image must be less than " + (this.maxFileSize * 0.000001) + "MB");
          return;
        }
      }

      this.images = new Array(files.length);
      this.loadedCount = 0;

      var _URL = window.URL || window.webkitURL;
      let self = this;

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        //check dimensions
        let image = new Image();
        image.onload = function () {
          let valid = true;
          if (this.width !== this.height) {
            valid = false;
            window.alert("Image must be square");
          }

          if (
            this.width < self.minImageSize ||
            this.height < self.minImageSize
          ) {
            valid = false;
            window.alert(
              "Image must be larger than " +
                self.minImageSize +
                "x" +
                self.minImageSize +
                "px"
            );
          }

          if (
            this.width > self.maxImageSize ||
            this.height > self.maxImageSize
          ) {
            valid = false;
            window.alert(
              "Image must equal to or smaller than " +
                self.maxImageSize +
                "x" +
                self.maxImageSize +
                "px"
            );
          }

          if (valid) {
            self.loadImage(file);
          }else{
            self.$refs.fileInput.value = ''
          }
        };
        image.src = _URL.createObjectURL(file);
      }
    },
    loadImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.images[this.loadedCount] = e.target.result;
        this.loadedCount++;
        if (this.loadedCount == this.images.length) {
          this.$emit("change", this.images);
        }
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style scoped>
.upload-btn-wrapper {
  position: relative;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}
.upload-btn-wrapper:hover {
  text-decoration: underline;
}

.upload-btn {
  border: none;
  color: white;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0px;
  cursor: pointer;
  opacity: 0;
}
</style>
