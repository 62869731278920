import axios from "axios";

const state = {
  pending: [],
  approved: [],
  submissions: [],
  isLoading: false,
  submission: null,
};

// getters
const getters = {};

// actions
const actions = {
  getSubmission(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/submissions/${id}?size=max`, {
          headers: {
            Authorization: `Bearer ${context.rootState.account.token}`
          }
        })
        .then((response) => {
          context.commit("setSubmission", response.data.submission);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUserSubmissions(context) {
    context.commit("setIsLoading", true);

    return new Promise((resolve, reject) => {
      //let id = JSON.parse(context.rootState.account.user).id;
      axios
        .get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/submissions/pending`, {
          headers: {
            Authorization: "Bearer " + context.rootState.account.token
          }
        })
        .then((response) => {
          context.commit("setPending", response.data.pending);
          context.commit("setApproved", response.data.approved.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("setIsLoading", false);
        });
    });
  },
  getApprovedSubmissions(context, page) {
    context.commit("setIsLoading", true);

    return new Promise((resolve, reject) => {
      //let id = JSON.parse(context.rootState.account.user).id;
      axios
        .get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/submissions/approved?page=${page}`, {
          headers: {
            Authorization: "Bearer " + context.rootState.account.token
          }
        })
        .then((response) => {
          context.commit("setApproved", response.data.approved);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("setIsLoading", false);
        });
    });
  },
  getPendingSubmissions(context, page) {
    context.commit("setIsLoading", true);

    return new Promise((resolve, reject) => {
      //let id = JSON.parse(context.rootState.account.user).id;
      axios
        .get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/submissions/pending?page=${page}`, {
          headers: {
            Authorization: `Bearer ${context.rootState.account.token}`
          }
        })
        .then((response) => {
          context.commit("setPending", response.data.pending);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("setIsLoading", false);
        });
    });
  },
  getSubmissions(context) {
    context.commit("setIsLoading", true);

    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/submissions`, {
          headers: {
            Authorization: `Bearer ${context.rootState.account.token}`
          }
        })
        .then((response) => {
          context.commit("setSubmissions", response.data.submissions);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("setIsLoading", false);
        });
    });
  },
  deleteSubmission(context, id) {
    context.commit("setIsLoading", true);

    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/submissions/${id}`, {
          headers: {
            Authorization: `Bearer ${context.rootState.account.token}`
          }
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("setIsLoading", false);
        })
    });
  }
};

// mutations
const mutations = {
  setPending(state, submissions) {
    state.pending = submissions;
  },
  setApproved(state, submissions) {
    state.approved = submissions;
  },
  setSubmissions(state, submissions) {
    state.submissions = submissions;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setSubmission(state, submission){
    state.submission = submission
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
