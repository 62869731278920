import Vue from "vue";
import Vuex from "vuex";
import account from "./modules/account";
import search from "./modules/search";
import album from "./modules/album";
import submissions from "./modules/submissions";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account: account,
    search: search,
    album: album,
    submissions: submissions,
  },
});
