import axios from "axios";

axios.defaults.withCredentials = false;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const state = {
  /*album: localStorage.getItem("album") || null,
  polygons: localStorage.getItem("polygons") || [],*/
  album: null,
  polygons: [],
  maxNumArtwork: 18,
  isSubmitting: false,
  submittedAlbum: localStorage.getItem("submittedAlbum") || null
};

// getters
const getters = {
  getFrontCover: (state) => (size) => {
    if (state.album.front_cover_from_service) {
      return state.album.service_front_cover;
    } else {
      return state.album.front_cover + '?w=' + size;
    }

  }
};

// actions
const actions = {
  getAlbum(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/lookup?id=${id}&service=spotify`, {
          headers: {
            Authorization: `Bearer ${context.rootState.account.token}`
          }
        })
        .then((response) => {
          context.commit("setAlbum", response.data.album);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSubmission(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/submissions/${id}?size=max`, {
          headers: {
            Authorization: `Bearer ${context.rootState.account.token}`
          }
        })
        .then((response) => {

          context.commit("setAlbum", response.data.submission);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  submitAlbum(context, album) {
    context.commit("isSubmitting", true);

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/submit`, album, {
          headers: {
            Authorization: `Bearer ${context.rootState.account.token}`
          }
        })
        .then((response) => {
          context.commit("albumSubmitted", response.data.album);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("isSubmitting", false);
        });
    });
  },
  submitApprovedAlbum(context, album) {
    context.commit("isSubmitting", true);

    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/submissions/approve`, album, {
          headers: {
            Authorization: `Bearer ${context.rootState.account.token}`
          }
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit("isSubmitting", false);
        });
    });
  },
  deleteAlbum(context, id) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + context.rootState.account.token;

    axios.defaults.withCredentials = false;

    return new Promise((resolve, reject) => {
      axios
        .delete("/delete/", { params: { id: id } })
        .then((response) => {
          resolve(response);
          console.log("resolved");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clearSubmittedAlbum() {
    localStorage.removeItem("submittedAlbum");
  },
};

// mutations
const mutations = {
  setAlbum(state, album) {
    console.log('setAlbum')
    if (album.tracks) {
      album.tracks.forEach(function (track) {
        track.active = false;
      });
    }
    state.album = album;
    //localStorage.setItem("album", JSON.stringify(album));
  },
  clearAlbum(state) {
    console.log('clearAlbum')
    state.album = null;
    state.polygons = [];
  },
  setPolygons(state, polygons) {
    state.polygons = polygons;
    //localStorage.setItem("polygons", JSON.stringify(polygons));
  },
  isSubmitting(state, flag) {
    state.isSubmitting = flag;
  },
  albumSubmitted(state, album){
    state.submittedAlbum = album;
    localStorage.setItem("submittedAlbum", album);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
