<template>
  <div class="p-4" v-if="albums.length > 0">
    <table class="table-fixed w-full">
      <thead>
        <tr>
          <th class="text-left">Artist</th>
          <th class="text-left">Release</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="(album, index) in albums" :key="index">
        <td class="truncate">{{ album.artist }}</td>
        <td class="truncate">{{ album.title }} <span v-if="album.contentRating">({{ album.contentRating }})</span></td>
        <td class=" truncate">
          <div class="flex mb-4">
            <div v-if="album.back_cover">
              <img :src="album.back_cover" class="w-24" />
            </div>
            <img :src="album.front_cover" class="w-24" />
            <div
              v-if="album.artwork && album.artwork.length > 0">
              <img :src="getArtworkImage(album, 100)" class="w-24" />
            </div>
          </div>
        </td>
        <td>
          <div class="flex justify-center w-full">
            <div v-if="album.id && album.back_cover" class="flex justify-between w-full">
              <!--<div v-if="album.official && album.approved && album.user_id !== $store.getters['account/userId']" class="flex">
                <img src="@/assets/svg/locked.svg" width="20" />
                <div>Official</div>
                <a :href="`https://player.sleevenote.com/album/${album.id}`" target="_blank">View</a>
              </div>
              <div v-else-if="album.approved" class="flex">
                <span>Approved</span>
                <a :href="`https://player.sleevenote.com/album/${album.id}`" target="_blank">View</a>
                <router-link :to="`/album/${album.id}`">Edit</router-link>
              </div>
              <div v-else class="flex">
                <span>Pending</span>
                <router-link :to="`/album/${album.id}`">Add anyway</router-link>
              </div>
              <a href="#" @click.prevent="infoClicked(album)">
                <img src="@/assets/svg/info.svg" width="20px" style="display:block" />
              </a>-->
              <div class="w-full text-center space-x-2">
                <a :href="getMailTo(album)"
                  target="_blank"
                  class="text-black dark:text-white underline hover:no-underline dark:hover:bg-white dark:hover:text-black focus:outline-none">Contact us to replace</a>
                <a :href="getPlayerLink(album)"
                  target="_blank"
                  class="text-black dark:text-white underline hover:no-underline dark:hover:bg-white dark:hover:text-black focus:outline-none">View</a>
              </div>
            </div>
            <div v-else class="flex items-stretch w-full">
              <router-link
                :to="{ name: 'submission.create', query: { spotify_id: album.spotify_id } }"
                class="w-full text-center text-black dark:text-white bg-transparent border-2 border-black dark:border-white rounded-xl hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black transition-colors p-2 focus:outline-none">Add artwork</router-link>
              <!--<button @click="addArtwork(album)">Add artwork</button>-->
            </div>
          </div>
        </td>
      </tr>
    </table>

    <GeneralModal v-if="showOfficialInfo" @ok="okClicked">
      <p>
        This release has been provided officially or restricted to authorised
        editors. For queries email
        <a
          href="mailto:support@sleevenote.com"
        >support@sleevenote.com</a>
      </p>
    </GeneralModal>
    <GeneralModal v-if="showAlbumApproved" @ok="okClicked">
      <p>Artwork has been supplied and approved.</p>
      <p>
        Click "View" to view approved artwork. Only replace artwork if you are
        an official source or you are improving and expanding on the approved
        artwork.
      </p>
      <p>
        Supplying an inferior or malicious replacement will jeopardise your
        account.
      </p>
    </GeneralModal>
    <GeneralModal v-if="showPending" @ok="okClicked">
      <p>
        Artwork has been submitted for this release and is waiting for approval,
        only supply artwork if you are an official source or you believe it to
        be of the highest quality.
      </p>
      <p>
        If in doubt check back once the pending submission has been approved or
        rejected.
      </p>
      <p>
        Supplying an inferior or malicious replacement will jeopardise your
        account.
      </p>
    </GeneralModal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from "vuex";
import GeneralModal from "@/components/GeneralModal.vue";

export default {
  name: "AlbumsList",
  components: {
    GeneralModal,
  },
  props: {
    albums: Array,
  },
  data() {
    return {
      showOfficialInfo: false,
      showAlbumApproved: false,
      showPending: false,
    };
  },
  methods: {
    ...mapGetters("album", {
      getFrontCover: "getFrontCover",
    }),
    getMailTo(album){
      return `mailto:support@sleevenote.com?subject=Replace%20Album%20Request&body=Artist: ${album.artist}%0D%0ATitle: ${album.title}%0D%0AID: ${album.id}%0D%0AUser: ${this.$store.state.account.user.email}`
    },
    getPlayerLink(album){
      return `${process.env.VUE_APP_PLAYER_URL}/release/${album.id}`
    },
    getAlbumFrontCover(album, size) {
      if (album.id) {
        if (album.front_cover_from_service) {
          return album.service_front_cover;
        } else {
          return album.front_cover + "?w=" + size;
        }
      }
      return album.front_cover.replace("{w}x{h}", size + "x" + size);
    },
    getAlbumBackCover(album, size) {
      if (album.id) {
        return album.back_cover + "?w=" + size;
      }
      return null;
    },
    getArtworkImage(album, size) {
      if (album.id && album.artwork.length > 0) {
        return album.artwork[0] + "?w=" + size;
      }
      return null;
    },
    infoClicked(album) {
      if (album.id) {
        if (album.official) {
          this.showOfficialInfo = true;
        } else if (album.approved) {
          this.showAlbumApproved = true;
        } else {
          this.showPending = true;
        }
      }
    },
    okClicked() {
      this.showOfficialInfo = false;
      this.showAlbumApproved = false;
      this.showPending = false;
    },
    addArtwork(album){
      this.$root.$emit('creating-album');
      axios.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/album/create`, { spotify_id: album.spotify_id })
      .then((response) => {
        this.$router.push({ name: 'album', params: { id: response.data.album.id }})
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {

      })
    }
  },
};
</script>