export default class Shape {
  constructor() {
    this.points = [];
    this.interpolatedPoints = [];

    this.bIsClosed = false;
    this.bIsMouseOver = false;
    this.bTrackHoveringOver = false;
    this.bMovingPoly = false;

    this.hoverAnchor = -1;
    this.draggingAnchor = -1;

    //this.bIsActive = true;
    //this.bHasTrack = false;
    this.track = null;
    this.bWillClose = false;

    this.mouse = {};
  }

  addPoint = function(pos) {
    //if we've hit the first point then we have closed the poly
    let hit = this.hitAnchor(pos, 10);
    if (hit === 0) {
      if(!this.bIsRectangle && this.points.length > 2){
        this.bIsClosed = true;
      }
    } else {
      this.points.push(pos);
    }
  };

  removePoint = function(i) {
    this.points.splice(i, 1);
    if (this.points.length < 3) {
      this.bIsClosed = false;
    }
  };

  setMouse = function(pos) {
    this.mouse = pos;

    let hit = this.hitAnchor(pos, 10);
    this.hoverAnchor = hit;
    if (hit === 0) {
      this.bWillClose = true;
    } else {
      this.bWillClose = false;
    }

    /*let i = this.hitAnchor(pos);
    console.log(i);
    if (i > -1) {
      this.highlightAnchor(i);
    }*/
  };

  clone = function(poly) {
    let self = this;
    poly.points.forEach(function(pos) {
      self.points.push(pos);
    });
  };

  movePoly = function(deltaX, deltaY) {
    this.points.forEach(function(pos) {
      pos.x += deltaX;
      pos.y += deltaY;
    });
  };

  drawDropShadow = function(points, context, x, y) {
    context.beginPath();
    context.moveTo(this.points[0].x + x, this.points[0].y + y);
    for (let i = 1; i < this.points.length; i++) {
      context.lineTo(this.points[i].x + x, this.points[i].y + y);
    }
    context.closePath();
    context.strokeStyle = "rgba(255,255,255,0.5)";
    context.stroke();
  };

  drawHandlesDropShadow = function(points, context, x, y) {
    context.beginPath();
    context.moveTo(points[0].x + x, points[0].y + y);
    for (var i = 1; i < points.length; i++) {
      context.lineTo(points[i].x + x, points[i].y + y);
    }
    context.closePath();
    context.strokeStyle = "rgba(255,255,255,0.5)";
    context.stroke();
  };

  draw = function(context, highlight, drawMode) {
    if (this.points.length > 1) {
      /*let p1 = this.points[0];
      let p2 = this.points[1];
      let dx = p2.x - p1.x;
      let dy = p2.y - p1.y;
      context.beginPath();
      context.moveTo(this.points[0].x, this.points[0].y);
      context.lineTo(this.points[0].x + dx, this.points[0].y + dy);
      context.strokeStyle = "rgba(255,255,255,1)";
      context.stroke();*/
      //this.drawDropShadow(this.points, context, 1, 1);

      context.beginPath();
      context.moveTo(this.points[0].x, this.points[0].y);
      for (let i = 1; i < this.points.length; i++) {
        context.lineTo(this.points[i].x, this.points[i].y);
      }

      if (highlight) {

        if (drawMode == "ERASE") {
          context.fillStyle = "rgba(255,0,0,0.5)";
        }
        else if (this.track) {
          context.fillStyle = "rgba(0,150,0,0.5)";
        } else {
          context.fillStyle = "rgba(255,0,0,0.5)";
        }
        context.fill();
      } else if (this.bTrackHoveringOver) {
        if (this.track) {
          context.fillStyle = "rgba(0,255,255,0.5)";
          context.fill();
        } else {
          context.fillStyle = "rgba(0,255,0,0.5)";
          context.fill();
        }
      } else if (this.track) {
        context.fillStyle = "rgba(0,255,0,0.5)";
        context.fill();
      } else if (this.bIsClosed) {
        context.fillStyle = "rgba(0,0,255,0.5)";
        context.fill();
      }

      for (let i = 1; i < this.points.length; i++) {
        context.beginPath();
        context.moveTo(this.points[i - 1].x, this.points[i - 1].y);
        context.lineTo(this.points[i].x, this.points[i].y);
        if (!this.bIsClosed && this.bWillClose) {
          context.strokeStyle = "rgb(0,255,0)";
        } else {
          context.strokeStyle = "rgb(0,0,255)";
        }
        context.stroke();
      }

      if (this.bIsClosed) {
        context.beginPath();
        context.moveTo(
          this.points[this.points.length - 1].x,
          this.points[this.points.length - 1].y
        );
        context.lineTo(this.points[0].x, this.points[0].y);
        context.strokeStyle = "rgb(0,0,255)";
        context.stroke();
        //  context.closePath();
      }

      /*if (!this.bIsClosed && this.bWillClose) {
        context.strokeStyle = "rgb(0,255,0)";
      } else {
        context.strokeStyle = "rgb(0,0,255)";
      }*/

      //context.stroke();
    }
  };

  drawTrackText = function(context) {
    //if (this.track) {
    context.strokeStyle = "rgb(0,0,0)";
    context.fillStyle = "rgb(0,0,0)";
    context.font = "10px Arial";
    context.fillText(this.track.name, this.points[0].x, this.points[0].y);
    //}
  };

  drawHandles = function(context, handleSize) {
    let self = this;
    this.points.forEach(function(pos, index) {
      let pts = [
        { x: pos.x - handleSize / 2, y: pos.y - handleSize / 2 },
        { x: pos.x + handleSize / 2, y: pos.y - handleSize / 2 },
        { x: pos.x + handleSize / 2, y: pos.y + handleSize / 2 },
        { x: pos.x - handleSize / 2, y: pos.y + handleSize / 2 },
      ];
      self.drawHandlesDropShadow(pts, context, 1, 1);

      context.beginPath();
      context.rect(
        pos.x - handleSize / 2,
        pos.y - handleSize / 2,
        handleSize,
        handleSize
      );
      context.closePath();
      if (self.hoverAnchor == index) {
        context.strokeStyle = "rgb(0,255,0)";
      } else {
        context.strokeStyle = "rgb(0,0,255)";
      }
      context.stroke();
    });
  };

  drawInterpolatedHandles = function(context, handleSize) {
    let self = this;
    this.interpolatedPoints.forEach(function(pos, index) {
      let points = [
        { x: pos.x - handleSize / 2, y: pos.y - handleSize / 2 },
        { x: pos.x + handleSize / 2, y: pos.y - handleSize / 2 },
        { x: pos.x + handleSize / 2, y: pos.y + handleSize / 2 },
        { x: pos.x - handleSize / 2, y: pos.y + handleSize / 2 },
      ];
      self.drawDropShadow(points, context, 1, 1);

      context.beginPath();
      context.rect(
        pos.x - handleSize / 2,
        pos.y - handleSize / 2,
        handleSize,
        handleSize
      );
      context.closePath();
      if (self.hoverAnchor == index) {
        context.strokeStyle = "rgb(0,255,0)";
      } else {
        context.strokeStyle = "rgb(0,0,255)";
      }
      context.stroke();
    });
  };

  highlightAnchor = function(hit) {
    for (let i = 0; i < this.points.length; i++) {
      if (i == hit) {
        this.points[i].bIsMouseOver = true;
      } else {
        this.points[i].bIsMouseOver = false;
      }
    }
  };

  insidePolygon = function(pos) {
    let x = pos.x;
    let y = pos.y;

    let inside = false;
    for (
      let i = 0, j = this.points.length - 1;
      i < this.points.length;
      j = i++
    ) {
      let xi = this.points[i].x,
        yi = this.points[i].y;
      let xj = this.points[j].x,
        yj = this.points[j].y;
      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };

  hitAnchor = function(pos, size) {
    var hit = -1;
    for (let i = 0; i < this.points.length; i++) {
      if (
        pos.x > this.points[i].x - size / 2 &&
        pos.x < this.points[i].x + size / 2
      ) {
        if (
          pos.y > this.points[i].y - size / 2 &&
          pos.y < this.points[i].y + size / 2
        ) {
          hit = i;
        }
      }
    }

    return hit;
  };

  hitLine = function(pos) {
    let currPoint = pos;
    let point1 = this.points[0];
    let point2 = this.points[1];
    let dxc = currPoint.x - point1.x;
    let dyc = currPoint.y - point1.y;

    let dxl = point2.x - point1.x;
    let dyl = point2.y - point1.y;

    let cross = dxc * dyl - dyc * dxl;
    return cross;
  };

  generateInterpolatedPositions = function() {
    this.interpolatedPoints = [];
    for (var i = 0; i < this.points.length; i++) {
      var p1 = i;
      var p2 = i + 1;
      if (p2 >= this.points.length) p2 = 0;

      var p = this.interpolate(this.points[p1], this.points[p2], 0.5);
      this.interpolatedPoints.push(p);
    }
  };

  interpolate = function(a, b, frac) {
    var nx = a.x + (b.x - a.x) * frac;
    var ny = a.y + (b.y - a.y) * frac;
    return { x: nx, y: ny };
  };
}

export class Rectangle extends Shape {
  constructor() {
    super();

    this.bIsRectangle = true;
  }
}

export class Polygon extends Shape {
  constructor() {
    super();

    this.bIsRectangle = false;
  }
}
