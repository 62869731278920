export function getPosition(e, context) {
  let rect = context.canvas.getBoundingClientRect();
  let x = mapRange(e.clientX - rect.left, 0, rect.width, 0, 400);
  let y = mapRange(e.clientY - rect.top, 0, rect.height, 0, 400);
  return { x: x, y: y };
}

export function dist(x1, y1, x2, y2) {
  let a = x1 - x2;
  let b = y1 - y2;

  return Math.sqrt(a * a + b * b);
}

export function mouseHasMoved(startPos, endPos, distance) {
  return Math.abs(dist(startPos.x, startPos.y, endPos.x, endPos.y)) > distance;
}

export function mapRange(num, in_min, in_max, out_min, out_max) {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

export function normalizePoints(points, size) {
  return points.map(function (v) {
    return {
      x: v.x / size,
      y: v.y / size,
    };
  });
}

export function convertImgToBase64URL(url, callback, outputFormat){
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
        var canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'), dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
        canvas = null;
    };
    img.src = url;
}

/*export default class Helpers {
  constructor() {
    console.log("New Helpers");
  }

  getPosition = function(e, context) {
    let rect = context.canvas.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    return { x: x, y: y };
  };

  dist = function(x1, y1, x2, y2) {
    let a = x1 - x2;
    let b = y1 - y2;

    return Math.sqrt(a * a + b * b);
  };

  mouseHasMoved = function(startPos, endPos, distance) {
    return (
      Math.abs(this.dist(startPos.x, startPos.y, endPos.x, endPos.y)) > distance
    );
  };
}*/
