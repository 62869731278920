<template>
  <div>{{ album.artist }}, {{ album.title }}</div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AlbumInfo",
  computed: {
    ...mapState("album", {
      album: (state) => state.album,
    }),
  },
};
</script>
