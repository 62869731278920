<template>
  <div class="album overflow-hidden" v-if="album">
    <AlbumInfo></AlbumInfo>
    <Navigator></Navigator>
    <div class="flex">
      <Artboard ref="artboard"></Artboard>
      <Tracklist ref="tracklist"></Tracklist>
    </div>
    <ConfirmationModal v-if="showConfirmation" @back="cancelSubmit" @submit="confirmSubmit"></ConfirmationModal>
    <SuccessfulModal
      v-if="showSuccessfullySubmitted"
      @ok="okSubmit"
      :bShowRegisterLink="!loggedIn()"></SuccessfulModal>
    <transition name="fade">
      <FrontCoverReplacementModal
        v-if="showFrontCoverReplacementModal"
        @close="showFrontCoverReplacementModal = false" />
    </transition>
    <transition name="fade">
      <div
        v-if="isLoading"
        class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-container">
          <div class="flex justify-center px-8">
            <div class="w-24">
              <Loader />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div v-else>
    <div v-if="albumNotFound" style="padding-left:1em">
      <h2>Sorry, album not found</h2>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

import AlbumInfo from "@/components/AlbumInfo.vue";
import Tracklist from "@/components/Tracklist.vue";
import Navigator from "@/components/Navigator.vue";
import Artboard from "@/components/Artboard.vue";
import { Polygon } from "@/polygon";
import { normalizePoints } from "@/helpers.js";
import CONSTANTS from "@/constants";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import SuccessfulModal from "@/components/modals/SuccessfulModal.vue";
import FrontCoverReplacementModal from "@/components/modals/FrontCoverReplacementModal.vue";

export default {
  name: "Album",
  components: {
    AlbumInfo,
    Tracklist,
    Navigator,
    Artboard,
    ConfirmationModal,
    SuccessfulModal,
    FrontCoverReplacementModal,
  },
  data() {
    return {
      showLinksModal: false,
      isLoading: false,
      showConfirmation: false,
      showSuccessfullySubmitted: false,
      alertBeforeLeaving: true,
      showFrontCoverReplacementModal: false,
      albumNotFound: false,
    };
  },
  props: {
    id: Number,
  },
  computed: {
    ...mapState("album", {
      album: (state) => state.album,
      polygons: (state) => state.polygons,
      maxNumArtwork: (state) => state.maxNumArtwork,
    }),
  },
  methods: {
    ...mapMutations("album", {
      setAlbum: "setAlbum",
      setPolygons: "setPolygons",
      clearAlbum: "clearAlbum",
    }),
    ...mapActions("album", {
      getAlbum: "getAlbum",
      getSubmission: "getSubmission",
      submitAlbum: "submitAlbum",
      submitApprovedAlbum: "submitApprovedAlbum",
      deleteAlbum: "deleteAlbum",
    }),
    ...mapActions("account", {
      destroyToken: "destroyToken",
    }),
    ...mapGetters("account", {
      isReturningVisitor: "isReturningVisitor",
      loggedIn: "loggedIn"
    }),
    ...mapActions("account", {
      setIsReturningVisitor: "setIsReturningVisitor"
    }),
    async getAlbumFromDb() {
      const id = this.$route.params.id;
      const spotify_id = this.$route.query.spotify_id;
      if(id){
        this.getSubmission(id)
        .then((response) => {
          /*let submission = response.data.submission;
          console.log(submission)
          convertImgToBase64URL(submission.back_cover, (base64Img) => {
            submission.back_cover = base64Img
          })

          if(!submission.front_cover_from_service){
            convertImgToBase64URL(submission.front_cover, (base64Img) => {
              submission.front_cover = base64Img
            })
          }
          let base64Artwork = []
          for(var i = 0; i<submission.artwork.length; i++){
            convertImgToBase64URL(submission.artwork[i], (base64Img) => {
              base64Artwork.push(base64Img)
            })
          }
          submission.artwork = base64Artwork*/

          this.setAlbum(response.data.submission);
          document.title = "Create on Sleevenote: " + this.album.artist + ", " + this.album.title;
          let polygons = [];
          this.album.tracks.forEach((track) => {
            if (track.polygon) {
              let poly = new Polygon();
              poly.points = [];
              track.polygon.points.forEach(function (p) {
                poly.points.push({
                  x: p.x * CONSTANTS.CANVAS_SIZE,
                  y: p.y * CONSTANTS.CANVAS_SIZE,
                });
              });
              poly.bIsRectangle = track.polygon.bIsRectangle;
              poly.bIsClosed = true;
              poly.track = { ...track };
              polygons.push(poly);
              track.polygon = poly;
            }
          });
          this.setPolygons(polygons);
          this.$refs.tracklist.enable();
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 401) {
            this.destroyToken().finally(() => {
              this.alertBeforeLeaving = false;
              this.$router.replace({
                name: "login",
                query: { redirect: this.$route.fullPath },
              });
            });
          }else if(error.response.status === 404) {
            this.albumNotFound = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });


      }else if(spotify_id){
        this.getAlbum(spotify_id)
        .then((response) => {
          this.setAlbum(response.data.album);
          document.title = "Create on Sleevenote: " + this.album.artist + ", " + this.album.title;
          let polygons = [];
          this.album.tracks.forEach((track) => {
            if (track.polygon) {
              let poly = new Polygon();
              poly.points = [];
              track.polygon.points.forEach(function (p) {
                poly.points.push({
                  x: p.x * CONSTANTS.CANVAS_SIZE,
                  y: p.y * CONSTANTS.CANVAS_SIZE,
                });
              });
              poly.bIsRectangle = track.polygon.bIsRectangle;
              poly.bIsClosed = true;
              poly.track = { ...track };
              polygons.push(poly);
              track.polygon = poly;
            }
          });
          this.setPolygons(polygons);
          this.$refs.tracklist.enable();
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 401) {
            this.destroyToken().finally(() => {
              this.alertBeforeLeaving = false;
              this.$router.replace({
                name: "login",
                query: { redirect: this.$route.fullPath },
              });
            });
          }else if(error.response.status === 404) {
            this.albumNotFound = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });


      }else{
        this.albumNotFound = true;
      }
    },
    backCoverUploaded(images) {
      if (images.length > 0) {
        Vue.set(this.album, 'back_cover', images[0])
        Vue.set(this.album, 'back_cover_updated', true)
      }
    },
    artworkUploaded(images) {
      if (images.length > 0) {
        let artworkImages = this.album.artwork;
        artworkImages = artworkImages.concat(images);
        if (artworkImages.length > this.maxNumArtwork) {
          artworkImages.splice(this.maxNumArtwork);
        }
        this.album.artwork = artworkImages;
        this.album.artwork_updated = true;
      }
    },
    frontCoverUploaded(images) {
      if (images.length > 0) {
        //this.album.itunes_cover = this.album.front_cover;
        this.album.front_cover = images[0];
        this.album.front_cover_from_service = false;
        this.album.front_cover_updated = true;
      }
    },
    prepareAlbumForSubmission() {
      let sanitizedAlbum = { ...this.album };
      sanitizedAlbum.tracks = sanitizedAlbum.tracks.map((track, index) => {
        return {
          itunes_id: track.itunes_id,
          name: track.name,
          disc: track.disc.toString(), //TODO: this shoud be int in the long run but the app needs to change
          number: track.number ? track.number.toString() : (index + 1).toString(), //TODO: this shoud be int in the long run but the app needs to change
          polygon: track.polygon
            ? {
                points: normalizePoints(
                  track.polygon.points,
                  CONSTANTS.CANVAS_SIZE
                ),
                bIsRectangle: track.polygon.bIsRectangle,
              }
            : null,
        };
      });
      return sanitizedAlbum;
    },
    submitArtwork() {
      this.showConfirmation = true;
      this.deleteUnassignedPolygons();
    },
    deleteUnassignedPolygons(){
      for (var i = this.polygons.length - 1; i >= 0; --i) {
        if (!this.polygons[i].track) {
            this.polygons.splice(i,1);
        }
      }
      this.$refs.artboard.resetActivePolygon();
    },
    cancelSubmit() {
      this.showConfirmation = false;
    },
    okSubmit() {
      this.showConfirmation = false;
      this.showSuccessfullySubmitted = false;
      this.alertBeforeLeaving = false;
      if(this.loggedIn()){
        this.$router.push({ name: "submissions" });
      }else{
        this.$router.push({ name: "search" });
      }
    },
    confirmSubmit() {
      this.isLoading = true;
      let sanitizedAlbum = this.prepareAlbumForSubmission();
      //sanitizedAlbum.official = true; //flag as official
      let self = this;
      this.submitAlbum(sanitizedAlbum)
        .then(() => {
          self.showConfirmation = false;
          self.showSuccessfullySubmitted = true;
        })
        .catch(function (error) {
          alert("Oh no! There was an error: " + error);
          self.showSuccessfullySubmitted = false;
          if (error.response.status === 401) {
            self.destroyToken().finally(() => {
              self.$router.replace({
                name: "login",
                query: { redirect: self.$route.fullPath },
              });
            });
          }
        })
        .finally(function () {
          self.isLoading = false;
        });
    },
    approveAlbum() {
      if (!window.confirm("Are you sure this album is ready?")) {
        return;
      }
      this.finishApproveAlbum()
    },
    finishApproveAlbum(){
      this.isLoading = true;
      let sanitizedAlbum = this.prepareAlbumForSubmission();
      let self = this;
      this.submitApprovedAlbum(sanitizedAlbum)
        .then(() => {
          alert("Album has been approved");
          this.alertBeforeLeaving = false;
          this.$router.push({ name: "toapprove" });
        })
        .catch((error) => {
          alert("Oh no! There was an error: " + error);
        })
        .finally(() => {
          self.isLoading = false;
        });
    },
    viewLinks () {

    },
    async deleteAlbumFromDb() {
      this.isLoading = true;
      this.deleteAlbum(this.album.id)
        .then(() => {
          this.alertBeforeLeaving = false;
          this.$router.push({ name: "submissions" });
        })
        .catch((error) => {
          alert("Oh no! There was an error: " + error);
          if (error.response.status === 401) {
            this.destroyToken().finally(() => {
              this.$router.replace({
                name: "login",
                query: { redirect: this.$route.fullPath },
              });
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deactivateTrack(track) {
      if (track.polygon) {
        this.polygons.forEach(function (poly) {
          if (poly.track && poly.track.itunes_id == track.itunes_id) {
            poly.track = null;
          }
        });

        track.polygon = null;
      }
    },
    activateTrack(track, polygon) {
      let polyAssigned = false;
      let trackAssigned = false;
      let matchedTrack = null;
      this.album.tracks.forEach(function (t) {
        if (t.polygon == polygon) {
          matchedTrack = t;
          polyAssigned = true;
        }
      });

      if (track.polygon) {
        trackAssigned = true;
      }

      if (polyAssigned || trackAssigned) {
        if (window.confirm("Already assigned. Replace?")) {
          if (matchedTrack) {
            matchedTrack.polygon = null;
          }
          this.polygons.forEach(function (poly) {
            if (poly.track && poly.track.itunes_id == track.itunes_id) {
              poly.track = null;
            }
          });
        }
      }
      /*if (polygon.track) {
        if (window.confirm("Alredy assigned. Replace?")) {
          polygon.track = null;
        }
      }*/
      //polygon.track = { ...track };
      Vue.set(polygon, 'track', JSON.parse(JSON.stringify(track)));
      delete polygon.track.polygon;
      //track.polygon = polygon;
      Vue.set(track, 'polygon', polygon);
    },
    cancelEditing() {
      this.$router.go(-1);
      //this.$router.push({ name: "search" });
    },
    showFrontCoverInfo() {
      this.showFrontCoverReplacementModal = true;
    },
  },
  created(){
    this.clearAlbum();
  },
  mounted() {
    this.isLoading = true;
    document.title = "Create on Sleevenote";

    this.getAlbumFromDb();

    this.$root.$on("backCoverUploaded", this.backCoverUploaded);
    this.$root.$on("artworkUploaded", this.artworkUploaded);
    this.$root.$on("frontCoverUploaded", this.frontCoverUploaded);
    this.$root.$on("submitArtwork", this.submitArtwork);
    this.$root.$on("cancelEditing", this.cancelEditing);
    this.$root.$on("approveAlbum", this.approveAlbum);
    this.$root.$on("viewLinks", this.viewLinks);
    this.$root.$on("deleteAlbum", this.deleteAlbumFromDb);
    this.$root.$on("dectivateTrack", this.deactivateTrack);
    this.$root.$on("activateTrack", this.activateTrack);
    this.$root.$on("frontCoverInfo", this.showFrontCoverInfo);

    document.addEventListener("keydown", this.keydown);
  },
  beforeRouteLeave(to, from, next) {
    if (this.alertBeforeLeaving) {
      if (window.confirm("Are you sure you want to leave?")) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  beforeDestroy() {
    this.$root.$off("backCoverUploaded", this.backCoverUploaded);
    this.$root.$off("artworkUploaded", this.artworkUploaded);
    this.$root.$off("frontCoverUploaded", this.frontCoverUploaded);
    this.$root.$off("submitArtwork", this.submitArtwork);
    this.$root.$off("cancelEditing", this.cancelEditing);
    this.$root.$off("approveAlbum", this.approveAlbum);
    this.$root.$off("viewLinks", this.viewLinks);
    this.$root.$off("deleteAlbum", this.deleteAlbumFromDb);
    this.$root.$off("dectivateTrack", this.deactivateTrack);
    this.$root.$off("activateTrack", this.activateTrack);
    this.$root.$off("frontCoverInfo", this.showFrontCoverInfo);

    document.removeEventListener("keydown", this.keydown);
  },
};
</script>

<style scoped>
.album {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
</style>