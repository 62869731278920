<template>
  <div class="submissions">
    <h3 class="text-black dark:text-white text-xl my-4">Your approved submissions</h3>
    <SubmissionsList
      :isLoading="isLoading"
      :submissions="approved"
      :showPreviewLink="true"
      :showStats="true"></SubmissionsList>
    <!--<pagination v-if="approved.length > 0" :data="approved" @pagination-change-page="getResults" :size="small">
      <span slot="prev-nav">&lt; Previous</span>
      <span slot="next-nav">Next &gt;</span>
    </pagination>-->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SubmissionsList from "@/components/SubmissionsList.vue";

export default {
  name: "PendingSubmissions",
  components: {
    SubmissionsList,
  },
  data() {
    return {
      isLoading: false,
      approved: [],
    };
  },
  mounted() {
    this.isLoading = true;
    this.getApprovedSubmissions(1)
      .then((response) => {
        this.approved = response.data.approved;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions('submissions', ['getApprovedSubmissions']),
  },
};
</script>

<style scoped>
::v-deep ul > li {
  display: inline-block;
  margin: 0.2rem;
  border: 1px solid #fff;
  padding: 0.5rem;
}
</style>
