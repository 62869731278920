<template>
  <div class="space-x-4">
    <router-link v-if="loggedIn" :to="{ name: 'submissions' }" class="hover:underline">Submissions</router-link>
    <router-link :to="{ name: 'search' }" class="rounded-md border-2 border-black dark:border-white px-2 py-1 transition-colors hover:bg-black hover:text-white dark:hover:text-black dark:hover:bg-white">Create a release</router-link>
    <!--<a href="https://www.youtube.com/channel/UCIksP5rVHUqhyH8sDeGkwaQ" target="_blank" class="hover:underline">Tutorial</a>-->
    <a href="https://sleevenote.notion.site/sleevenote/Sleevenote-Artwork-Guidelines-79485d5df93349e0926acec7f22b74a0" target="_blank" class="hover:underline">Guidelines</a>
    <a href="mailto:support@sleevenote.com" class="hover:underline">Email support</a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "UserNav",
  computed: {
    ...mapGetters("account", {
      loggedIn: "loggedIn"
    }),
  }

};
</script>