<template>
  <div class="toolbar">
    <div class="image-list-container w-full">
      <div v-if="front_cover_from_service" class="flex justify-start items-center">
        <div class="toolbar-text mr-2">
          Cover image supplied by Spotify
          <ImageUpload class="ml-2 font-bold" ref="frontCoverUpload" :multiple="false" @change="onFrontCoverChanged">
            <span>Upload replacement</span>
          </ImageUpload>
        </div>
        <button @click.prevent="infoClicked" class="w-8 h-8">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" width="20px" height="20px" viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
            <path fill="currentColor" d="M10,1.179c-4.872,0-8.821,3.949-8.821,8.821S5.128,18.82,10,18.82s8.82-3.948,8.82-8.82
              S14.872,1.179,10,1.179z M9.758,14.531c-0.66,0-1.2-0.528-1.192-1.192c-0.008-0.647,0.532-1.175,1.192-1.175
              c0.626,0,1.184,0.527,1.192,1.175C10.941,14.003,10.384,14.531,9.758,14.531z M11.665,10.086c-0.617,0.362-0.937,0.702-0.937,1.379
              v0.204h-2.01v-0.204c0-1.401,0.336-1.835,0.971-2.248c0.541-0.354,0.955-0.647,0.955-1.124c0-0.426-0.341-0.715-0.768-0.715
              c-0.459,0-0.851,0.345-0.851,0.834h-2.18C6.863,6.34,8.238,5.557,9.894,5.557c1.818,0,3.167,0.8,3.167,2.401
              C13.062,8.992,12.496,9.601,11.665,10.086z"/>
          </svg>
        </button>
      </div>
      <div v-if="!front_cover_from_service" class="image-list-item float-right tooltip">
        <button class="toolbar-btn delete fill-black dark:fill-white w-8 h-8" type="button" @click.prevent="$emit('delete')">
          <svg class="w-8 h-8" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <g>
              <path d="M383.398,146.487h-16.033h-52.064V99.155H196.699v47.332h-52.064h-16.033v20h16.033v246.357h222.73V166.487h16.033
                V146.487z M216.699,119.155h78.602v27.332h-78.602V119.155z M347.365,392.845h-182.73V166.487h32.064h118.602h32.064V392.845z"/>
              <rect x="295.301" y="197.902" width="20" height="157.379"/>
              <rect x="196.699" y="197.902" width="20" height="157.379"/>
              <rect x="246" y="197.902" width="20" height="157.379"/>
            </g>
          </svg>
          <span class="tooltiptext">Delete image</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUpload from "./ImageUpload.vue";
export default {
  name: "FrontCoverToolbar",
  components: {
    ImageUpload,
  },
  props: {
    front_cover_from_service: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onFrontCoverChanged() {
      this.$root.$emit(
        "frontCoverUploaded",
        this.$refs.frontCoverUpload.images
      );
    },
    infoClicked() {
      this.$root.$emit("frontCoverInfo", true);
    },
  },
};
</script>

<style scoped>
.toolbar {
  padding: 0 0.3rem;
  margin: 1rem;
  width: 68vh;
}
.toolbar-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.toolbar-btn {
  border: none;
  color: black;
  background-color: white;
  cursor: pointer;
  border: 1px solid #999999;
  background-color: #cccccc;
}
.toolbar-btn .right {
  float: right;
}
button.toolbar-btn.delete {

}
button.toolbar-btn:disabled,
button.toolbar-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.toolbar-btn:hover {
  border: 1px solid yellowgreen;
}
.image-list-container {

}
.image-list-container .image-list-item {
  width: 30px;
  height: 30px;
}
.image-list-item:last-of-type {
  margin-left: auto;
}
.image-list-container .image-list-item:not(:last-child) {
  margin-right: 0.5rem;
}
.image-list-container .image-list-item:first-child {
  margin-left: 0;
}
.image-list-item > img {
  width: 100%;
}
.active {
  border: 3px solid yellowgreen;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.toolbar-text{
  font-size: 0.8em;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: white;
  border: 2px solid black;
  color: black;
  text-align: center;
  padding: 5px;

  position: absolute;
  width: 110px;
  top: 40px;
  left: 50%;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
