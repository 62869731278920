<template>
  <div class="artboard" v-if="album">
    <div class="artboard-header">
      <Toolbar
        :class="{ hidden: !showToolbar }"
        class="toolbar"
        ref="toolbar"
        @modeChanged="modeChanged"
        @buttonPressed="buttonPressed"
      ></Toolbar>

      <FrontCoverToolbar
        :class="{ hidden: !showCoverNotification }"
        class="toolbar cover-notification"
        :front_cover_from_service="album.front_cover_from_service"
        @delete="deleteFrontCover"
      ></FrontCoverToolbar>

    </div>

    <div class="swiper-control" id="swiper-control">
      <div class="swiper-track" id="swiper-track">
        <div v-if="!album.back_cover" class="swiper-item bg-gray-500">
          <ImageUpload
            class="upload-btn-icon upload-btn-lrg text-white"
            ref="backCoverUpload"
            :multiple="false"
            @change="onBackCoverChanged"
          >
            <button class="upload-btn" type="button">
              <svg
                version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" width="60px" height="60px" viewBox="0 0 60 60" enable-background="new 0 0 60 60" xml:space="preserve">
                <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="30" y1="11" x2="30" y2="49"/>
                <line fill="none" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" x1="11" y1="30" x2="49" y2="30"/>
              </svg>
            </button>
          </ImageUpload>
          <p class="text-white">Upload tracklist image</p>
        </div>
        <div v-else class="swiper-item grey">
          <img :src="album.back_cover" width="100%" />
          <Canvas ref="canvas" @modeChanged="modeChanged"></Canvas>
        </div>
        <div class="swiper-item grey">
          <img :src="frontCover" width="100%" />
        </div>
        <div
          v-for="(artwork, index) in album.artwork"
          :key="index"
          class="swiper-item grey"
          @click.prevent="artworkSelected(index)"
        >
          <img v-bind:src="artwork" width="100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

import Toolbar from "./Toolbar.vue";
import FrontCoverToolbar from "./FrontCoverToolbar.vue";
import ImageUpload from "./ImageUpload.vue";
//import Swiper from "@/components/Swiper.vue";
import Canvas from "./Canvas.vue";
//import CoverCarousel from "@/components/CoverCarousel.vue";

export default {
  name: "Artboard",
  data() {
    return {
      swiperTrack: null,
      showToolbar: true,
      showCoverNotification: false,
      currentIndex: 0,
    };
  },
  components: {
    Toolbar,
    FrontCoverToolbar,
    ImageUpload,
    Canvas,
  },
  props: {
    hijackKeys: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState("album", {
      album: (state) => state.album,
    }),
    frontCover(){
      return this.album.front_cover_from_service ? this.album.service_front_cover : this.album.front_cover
    }
  },
  created() {
    window.addEventListener("resize", this.windowResized);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResized);
  },
  mounted() {
    //console.log(this.$refs.swiper);
    //this.$watch("$refs.swiper.roundedViewportX", new_value => {
    //console.log(new_value);
    //});
    this.$refs.toolbar.disable();
    this.$refs.toolbar.hideDelete();

    this.swiperTrack = document.getElementById("swiper-track");

    this.windowResized();

    this.$root.$on("changeSelection", this.changeSelection);
    this.$root.$on("buttonPressed", this.buttonPressed);
    this.$root.$on("backCoverUploaded", this.setCanvas);

    this.setCanvas();
  },
  beforeDestroy() {
    this.$root.$off("changeSelection", this.changeSelection);
    this.$root.$off("buttonPressed", this.buttonPressed);
    this.$root.$off("backCoverUploaded", this.setCanvas);
  },
  methods: {
    ...mapGetters("album", {
      getFrontCover: "getFrontCover",
    }),
    resetActivePolygon(){
      this.$refs.canvas.activePoly = -1
    },
    setCanvas() {
      if (this.album.back_cover) {
        this.$refs.toolbar.enable();
        this.$refs.toolbar.enableDelete();
        this.$refs.toolbar.showDelete();

        let self = this;
        Vue.nextTick(function () {
          //let item = document.getElementsByClassName("swiper-item")[0];
          //let itemRect = item.getBoundingClientRect();
          //let width = itemRect.width;
          //let height = itemRect.height;
          //self.$refs.canvas.setSize(width, height);
          self.$refs.canvas.enable();
        });
      } else {
        this.$refs.toolbar.disable();
        this.$refs.toolbar.disableDelete();
      }
    },
    windowResized() {},
    modeChanged: function (m) {
      this.$refs.canvas.changeMode(m);
      this.$refs.toolbar.changeMode(m);
    },
    buttonPressed: function (b) {
      if (b == "UNDO") {
        this.$refs.canvas.undo();
      } else if (b == "REDO") {
        this.$refs.canvas.redo();
      } else if (b == "DELETE") {
        if (window.confirm("Are you sure you want to delete this image?")) {
          if (this.currentIndex == 0) {
            this.album.back_cover = null;
            this.$refs.toolbar.disableDelete()
            this.$refs.toolbar.disable()
          } else if (this.currentIndex == 1) {
            //TODO: refactor
            //this.album.front_cover = this.album.itunes_cover;
            //this.album.front_cover_from_service = true;
          } else {
            this.currentIndex -= 2;
            this.album.artwork.splice(this.currentIndex, 1);
            this.$root.$emit("changeSelection", this.currentIndex + 1);
          }
        }
      } else if (b == "DUPLICATE") {
        this.$refs.canvas.duplicateActivePoly();
      } else if (b == "CLEAR") {
        if (window.confirm("Delete all shapes?")) {
          this.$refs.canvas.clear();
        }
      }
    },
    deleteFrontCover() {
      if (window.confirm("Are you sure you want to delete this image?")) {
        //this.album.front_cover = this.album.itunes_cover;
        this.album.front_cover_from_service = true;
      }
    },
    changeSelection(e) {
      this.currentIndex = e;
      let translate = this.currentIndex * 68;
      this.swiperTrack.style.transform = "translate(-" + translate + "vh,0)";

      //if we are on the front cover we cannot delete
      if (e == 0) {
        this.$refs.toolbar.show();
        this.showCoverNotification = false;
        this.showToolbar = true;
      } else if (e == 1) {
        this.$refs.toolbar.hide();
        this.showCoverNotification = true;
        this.showToolbar = false;
      } else {
        this.$refs.toolbar.hide();
        this.showCoverNotification = false;
        this.showToolbar = true;
      }

      if (e == 0 && this.album.back_cover) {
        this.$refs.toolbar.enable();
        this.$refs.toolbar.enableDelete();
        this.$refs.canvas.enable();
      } else {
        this.$refs.toolbar.disable();
        if (this.$refs.canvas) {
          this.$refs.canvas.disable();
        }
      }
    },
    onBackCoverChanged() {
      this.$root.$emit("backCoverUploaded", this.$refs.backCoverUpload.images);
    },
    onArtworkChanged() {
      this.$root.$emit("artworkUploaded", this.$refs.artworkUpload.images);
    },
  },
};
</script>

<style scoped>
.artboard {
  flex-grow: 1;
  overflow: hidden;
}
.artboard > .artboard-header {
  height: 2.7rem;
}
.toolbar {
  margin-left: 25%;
  max-width: 68vh;
}
.upload-btn-lrg {
  cursor: pointer;
}
.upload-btn-icon {
  max-width: 5rem;
}
.swiper-control {
  flex-grow: 1;
  width: 100%;
  margin-left: 25%;
}
.swiper-track {
  display: flex;
  width: 100%;
  height: 100%;
  transition: all 0.25s ease-out;
}
.swiper-item {
  flex-shrink: 0;
  width: 68vh;
  height: 68vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
}
.swiper-item img {
  height: 100%;
  object-fit: cover;
}
.grey {
  background-color: rgb(90, 90, 90);
}
.cover-notification {
  font-size: 0.9rem;
}
.hidden {
  display: none;
}
</style>
