<template>
  <div class="tracklist text-black dark:text-white">
    <div class="header" :class="{ 'disabled': !bTracklistEnabled }">
      <div class="font-bold">Tracklist</div>
      <p>Draw shapes and drag onto artwork</p>
    </div>
    <div class="list text-black dark:text-white" :class="{ 'disabled': !bIsEnabled }">
      <ol>
        <li v-for="(track, index) in album.tracks"
            :key="index"
            v-bind:class="{ active: track.polygon }"
            @click.prevent="deactivateTrack(track)"
            @mouseover="track.bIsMouseOver = true"
            @mouseleave="track.bIsMouseOver = false">
          <span draggable="true" v-on:dragend="dragend(track, $event)"
          class="text-black dark:text-white truncate"
          :class="{ 'cursor-not-allowed': !bIsEnabled }">{{ padIndex(index+1) }}. {{ track.name }}</span>
        </li>
      </ol>
    </div>
    <div class="footer">
      <!--<button @click.prevent="addLinks">Add Links</button>-->
      <div v-if="!loggedIn() || !user.is_admin" class="flex justify-evenly">
        <button class="underline hover:no-underline" @click.prevent="cancelEditing" :disabled="isSubmitting">Cancel</button>
        <button
          class="px-2 py-1 rounded-md text-black border-2 border-black dark:text-white dark:border-white transition-colors hover:bg-black hover:text-white dark:hover:text-black dark:hover:bg-white"
          :class="{ 'border-gray-500 text-gray-500 cursor-not-allowed' : !readyForSubmit || isSubmitting }"
          @click.prevent="submitArtwork"
          :disabled="!readyForSubmit || isSubmitting">Submit for approval</button>
      </div>
      <div v-if="loggedIn() && user.is_admin" class="flex justify-evenly">
        <button class="no-border" @click.prevent="deleteAlbum">Delete</button>
        <button class="px-2 py-1 rounded-md text-black border-2 border-black dark:text-white dark:border-white" v-if="!isSubmitting" @click.prevent="approveAlbum">Approve</button>
        <button class="px-2 py-1 rounded-md text-black border-2 border-black dark:text-white dark:border-white" @click.prevent="viewLinks">View links</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Tracklist",
  props: {},
  data() {
    return {
      bIsEnabled: false,
      bTracklistEnabled: false
    };
  },
  computed: {
    ...mapState("album", ['album', 'polygons', 'isSubmitting']),
    ...mapState('account', ['user']),
    readyForSubmit() {
      let count = 0;
      this.album.tracks.forEach(function (track) {
        if (track.polygon) {
          count++;
        }
      });
      /*if (this.polygons.length !== this.album.tracks.length) {
        return false;
      }*/
      return this.album.tracks.length == count;
    },
  },
  mounted() {
    this.$root.$on("buttonPressed", this.buttonPressed);
    this.$root.$on("polyErased", this.polyErased);
    this.$root.$on("changeSelection", this.changeSelection);
  },
  beforeDestroy() {
    this.$root.$off("buttonPressed", this.buttonPressed);
    this.$root.$off("polyErased", this.polyErased);
    this.$root.$off("changeSelection", this.changeSelection);
  },
  methods: {
    ...mapGetters("account", ['loggedIn']),
    changeSelection(e){
      if (e == 0) {
        this.bTracklistEnabled = true;
        this.bIsEnabled = true;
      } else {
        this.bTracklistEnabled = false;
        this.bIsEnabled = false;
      }
    },
    buttonPressed(e) {
      if (e == "CLEAR") {
        this.deactivateAllTracks();
      }
    },
    polyErased(poly) {
      if (poly.track) {
        let self = this;
        this.album.tracks.forEach(function (track) {
          if (poly.track.itunes_id == track.itunes_id) {
            self.deactivateTrack(track);
          }
        });
      }
    },
    dragend(track, e) {
      this.$root.$emit("trackDragend", { track: track, event: e });
    },
    deactivateTrack(track) {
      //if (track.polygon) {
      this.$root.$emit("dectivateTrack", track);
      //}

      //track.polygon.track = null;
      //track.polygon = null;
    },
    padIndex(index){
      if(index < 10){
        return '0' + index;
      }
      return index;
    },
    deactivateAllTracks() {
      let self = this;
      this.album.tracks.forEach(function (track) {
        self.deactivateTrack(track);
      });
    },
    submitArtwork() {
      this.$root.$emit("submitArtwork");
    },
    cancelEditing() {
      this.$root.$emit("cancelEditing");
    },
    deleteAlbum() {
      if (window.confirm("Are you sure you want to delete this?")) {
        if (window.confirm("Are you sure you are sure?")) {
          this.$root.$emit("deleteAlbum");
        }
      }
    },
    approveAlbum() {
      this.$root.$emit("approveAlbum");
    },
    addLinks() {
      this.$root.$emit("addLinks");
    },
    viewLinks() {
      this.$root.$emit("viewLinks");
    },
    disable() {
      this.bIsEnabled = false;
      this.bTracklistEnabled = false;
    },
    enable() {
      this.bIsEnabled = true;
      this.bTracklistEnabled = true;
    },
  },
};
</script>

<style scoped>
.tracklist {
  position: relative;
  min-height: 300px;
  min-width: 300px;
  width: 300px;
  opacity: 1;
}
.disabled{
  opacity: 0.5;
}
.tracklist > .header {
  position: relative;
  height: 4rem;
  top: 0;
  padding: 0.5rem;
}
/*.tracklist > .header > .title {
  font-size: 1.4rem;
  font-weight: bold;
*/
.tracklist > .list {
  position: absolute;
  top: 4rem;
  bottom: 4rem;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 0.5rem;
}
.tracklist > .footer {
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  width: 100%;
}


.list > ol {
  padding: 0;
}
.list > ol {
  /*counter-reset: item;
  list-style: decimal-leading-zero;*/
}
.list > ol > li {
  /*counter-increment: item;
  margin-bottom: 0.5rem;
  font-weight: bold;

  cursor: pointer;
  padding: 0.1em;
  border-radius: 4px;*/
  margin-left: 2em;
  display: flex;
  flex-direction: column;
  padding: 0.1em;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
}
.list > ol > li:hover {
  background-color: rgb(120, 120, 120);
}
.list > ol > li > span {
  /*border-radius: 4px;
  padding: 0.1em;*/
}
.list > ol > li > span:active {
  background-color: rgb(120, 120, 120);
}
/*.list > ol > li:hover::before {
  background-color: rgb(120, 120, 120);
}*/
.list > ol > li::before {
  position: absolute;
  left: 0.5rem;
  /*content: counter(item);*/
  content: " ";
  background: transparent;
  border-radius: 100%;
  color: white;
  border: 2px solid red;
  width: 1em;
  height: 1em;
  text-align: center;
  display: inline-block;
}
.list > ol > li.active::before {
  border: 2px solid green;
  background-color: green;
}
/*.list > ol > li.active:hover::before {
  content: "x";
  color: black;
}*/
</style>
