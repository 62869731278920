import axios from "axios";

const state = {
  searchResults: [],
};

// getters
const getters = {};

// actions
const actions = {
  performSearch(context, term) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/search?term=${term}&service=spotify`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    });
  },
};

// mutations
const mutations = {
  setSearchResults(state, results) {
    state.searchResults = results;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
