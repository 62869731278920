<template>
  <div>
    <div class="mt-4">
      <h2 class="title">Submissions</h2>
    </div>
    <div>
      <div v-if="!user.is_admin">
        <PendingSubmissions></PendingSubmissions>
      </div>
      <br />
      <ApprovedSubmissions></ApprovedSubmissions>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PendingSubmissions from '@/components/PendingSubmissions.vue'
import ApprovedSubmissions from '@/components/ApprovedSubmissions.vue'

export default {
  name: "Submissions",
  components: {
    PendingSubmissions,
    ApprovedSubmissions,
  },
  computed: {
    ...mapState('account', ['user']),
  },
  mounted() {
    document.title = "Create on Sleevenote: Submissions";
  }
};
</script>